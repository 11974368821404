import React from 'react';

const Logo = ({ baseLayer, color0 }) => (
  <svg
    version="1.1"
    className={baseLayer}
    x="0px"
    y="0px"
    viewBox="0 0 320 100"
  >
    {/* <style type="text/css">
	.st0{fill:#F0F0F0;}
</style> */}
    <g>
      <g>
        <g>
          <g>
            <g>
              <path
                className={color0}
                d="M64.3,50.2c0-0.2-0.1-0.4-0.1-0.5s-0.1-0.3-0.1-0.3"
              />
              <path
                className={color0}
                d="M64.4,49.5L64.4,49.5c-0.1-0.5-0.3-1.7-0.4-2c0.1,0.1,0.1,0,0.2,0.4c-0.3-0.8-0.4-1.2-0.5-1.4
						s-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.8-1.3c0.1,0.1,0.2,0.3,0.3,0.6c0.3,0.6,0.4,1.1,0.3,0.9c0.5,1.2,0.4,1.3,0.6,1.4
						c0.2,0.4,0.3,0.8,0.3,1s0,0.2-0.2-0.3c0,0,0.1,0.6,0.3,1.2"
              />
              <path
                className={color0}
                d="M62.1,42.9c0,0-0.2-0.2-0.3-0.4c-0.2-0.2-0.4-0.4-0.3-0.4c0,0-0.3-0.4-0.4-0.8c-0.1-0.2-0.3-0.4-0.3-0.6
						c-0.1-0.1-0.1-0.3-0.1-0.3c-0.1,0-0.9-1.1-0.4-0.3c0.1,0.1,0.3,0.4,0.4,0.6c-0.2-0.1-1.4-1.9-1.3-1.4c0.8,1.1,0.9,1.3,1.5,2.1
						c0.2,0.3,0.3,0.6,0.4,0.8c0.1,0.3,0.3,0.5,0.4,0.7c0.2,0.4,0.4,0.8,0.5,1.1c0.2,0.3,0.3,0.4,0.5,0.8c-0.1-0.1-0.1-0.2-0.1-0.3
						l0.2-0.1c-0.3-0.4-0.4-0.8-0.6-1.1c0,0,0.3,0.4,0.6,0.9s0.6,0.9,0.6,0.9c-0.1-0.4-0.3-1-0.6-1.7c-0.4-0.6-0.7-1.3-0.9-1.7
						C62.1,42.7,61.2,41.1,62.1,42.9z"
              />
              <path
                className={color0}
                d="M62.6,44.8C62.6,44.8,62.7,44.8,62.6,44.8C62.7,44.8,62.6,44.8,62.6,44.8z"
              />
              <path
                className={color0}
                d="M46.8,41.2c-0.4-0.3-0.5-0.3-0.6-0.3c0,0,0.3,0.2,0.6,0.4c0.3,0.3,0.8,0.7,1.2,1.1
						c0.4,0.4,0.8,0.9,1.1,1.3c0.3,0.4,0.4,0.6,0.4,0.6c-0.1-0.1-0.8-1.3-0.3-0.6c-0.2-0.4-1.1-1.3-1.3-1.4l0.3,0.2
						c-0.8-0.8-1.3-1.4-0.7-0.9v-0.1c0,0-0.6-0.4-1.3-0.8c-0.3-0.2-0.7-0.4-0.9-0.5S44.9,40,44.9,40C45.8,40.7,45.7,40.4,46.8,41.2z
						"
              />
              <path
                className={color0}
                d="M30,54.7c-0.4-0.4-0.4-0.5-0.4-0.4C29.7,54.4,29.9,54.6,30,54.7z"
              />
              <path
                className={color0}
                d="M44.2,39.8C44.2,39.8,44.1,39.8,44.2,39.8c-0.2-0.1-0.3-0.2-0.4-0.2c-0.3-0.1-0.4-0.1-0.4-0.1
						C43.7,39.7,44,39.7,44.2,39.8z"
              />
              <path
                className={color0}
                d="M50.7,42.9L50.7,42.9c0,0-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.2l0,0C50.5,42.8,50.6,42.8,50.7,42.9z"
              />
              <path
                className={color0}
                d="M24.5,43.4c-0.1,0.1-0.1,0.1-0.2,0.3C24.4,43.6,24.4,43.5,24.5,43.4z"
              />
              <path
                className={color0}
                d="M55.4,56.5c0,0,0-0.1,0-0.4c0-0.3,0-0.6,0-0.8c0-0.3,0-0.6-0.1-0.8c0-0.3-0.1-0.4-0.1-0.4
						c0.1,0.3,0-0.9,0.1-0.3c0-0.2-0.1-1.1-0.1-0.8l0.1,0.5C55,52.2,55,53,54.5,51c0.2,1.3,0.3,1.8,0.4,2.5c0.1,0.3,0.1,0.4,0.1,0.6
						c0,0,0.1,0.5,0.1,1c0.1,0.5,0.1,1,0.1,1s0,0.1,0.1,0.3C55.4,56.3,55.4,56.5,55.4,56.5z"
              />
              <path className={color0} d="M43.9,57.2L43.9,57.2L43.9,57.2z" />
              <path
                className={color0}
                d="M43.9,57.3L43.9,57.3C43.9,57.3,43.8,57.8,43.9,57.3z"
              />
              <path
                className={color0}
                d="M44.6,39.9c-0.1,0-0.2,0-0.4-0.1l0.7,0.3C44.8,40.1,44.7,40,44.6,39.9z"
              />
              <path
                className={color0}
                d="M46.2,40.5c0,0,0.4,0.3,0.8,0.6c0.2,0.1,0.4,0.3,0.5,0.4c0.1,0.1,0.2,0.2,0.2,0.2s0.1,0.1,0.3,0.2
						c0.1,0.1,0.3,0.2,0.3,0.2C47.6,41.6,47,41.1,46.2,40.5z"
              />
              <path
                className={color0}
                d="M26.6,41.8c-0.4,0.3-0.6,0.4-0.8,0.6c-0.2,0.1-0.3,0.2-0.3,0.3c-0.1,0.1,0-0.1,0.2-0.3
						c0.3-0.3,0.9-0.8,0.2-0.3c-0.4,0.3-0.6,0.6-0.8,0.8c-0.2,0.3-0.3,0.4-0.6,0.6c0.3-0.3,0.4-0.4,0.6-0.6s0.3-0.2,0.3-0.3
						c0.1-0.1,0.1,0,0.1,0.1s0,0.1,0.1,0.1s0.4-0.3,0.9-0.7c-0.3,0.4-1.5,1.4-2.1,2.1c-0.8,0.7,0.5-0.8,0.6-1
						c-0.8,0.8-0.6,0.7-0.9,1.1c-1.2,1.3-1.8,3.1-2.4,4c-0.4,1.2-0.3,1.2-0.4,1.9c0.1,0.1,0.2-0.4,0.4-0.9c0.1-0.3,0.2-0.5,0.3-0.8
						c0.1-0.3,0.2-0.4,0.3-0.5c-0.1,0.1-0.1,0.3-0.1,0.2c0,0,0.1-0.3,0.2-0.5c0.1-0.3,0.3-0.5,0.3-0.5c-0.5,0.9-0.1-0.2,0.1-0.7
						c0.2-0.4,0.6-1,0.6-0.9L23,46.2c0.4-0.6,0.8-1.5,1.5-2c0.1,0-0.1,0.3-0.1,0.3c0.5-0.8,1.1-1.4,2.2-2.3l-0.5,0.6
						c0.2-0.2,0.4-0.4,0.8-0.7c0.3-0.3,0.7-0.5,1.1-0.7l0.3-0.2h0.1c0.1,0-0.1,0-0.1,0l0,0l0,0l0,0l0,0l0,0l0.1-0.1l0.2-0.1
						c0.3-0.1,0.5-0.2,0.8-0.3c0.5-0.2,0.9-0.3,1.4-0.4c-0.2,0.1-0.6,0.1-0.4,0.1c1-0.4,1.8-0.5,1.4-0.3c0.3-0.1,0.6-0.2,1-0.3
						s0.9-0.2,1.4-0.3c1-0.1,2.1-0.3,2.7-0.4c0,0,0.1,0,0.3-0.1c0.1,0,0.2,0,0.3-0.1h0.1l0,0l0,0l0,0h-0.1l0,0l0,0
						c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.3,0s0.3,0,0.5,0s0.4,0,0.6,0c0.4,0,0.8,0,1.1,0c0.8,0.1,1.6,0.2,2.2,0.3
						c0.3,0.1,0.6,0.1,0.7,0.2c0.2,0.1,0.3,0.1,0.3,0.1c-0.3-0.1-0.5-0.2-0.8-0.3c-0.5-0.4-0.8-0.4,0.2-0.2c-1-0.3-0.6-0.1-2.1-0.5
						c0.6,0.1,0.8-0.1-0.6-0.4C39.6,38,39.1,38,38.7,38c-0.3,0-0.4,0-0.7,0h-0.3h0.1h-0.1h-0.1c-0.8,0.1-1.6,0.2-2.3,0.4
						c-1.4,0.4-2.9,0.9-4.5,1.6c-0.1-0.1-0.8,0.2-1.6,0.5c-0.2,0.1-0.4,0.2-0.6,0.3l-0.2,0.1L28.3,41c-0.3-0.1-0.4,0-0.5,0
						c-0.4,0.2-0.8,0.4-1,0.6c0.2-0.2,0.4-0.4,0.8-0.6c0.2-0.1,0.4-0.3,0.6-0.3c0.3-0.1,0.5-0.2,0.7-0.3c-0.1,0.1-0.3,0.1-0.6,0.3
						c-0.1,0.1-0.2,0.1-0.3,0.1s-0.2,0.1-0.3,0.1c-0.2,0.1-0.4,0.2-0.6,0.3s-0.4,0.3-0.6,0.3C26.7,41.4,26.6,41.8,26.6,41.8z"
              />
              <path
                className={color0}
                d="M34.5,38.5c-0.1,0-0.2,0-0.3,0C34.2,38.5,34.4,38.5,34.5,38.5z"
              />
              <path
                className={color0}
                d="M29.3,40.2L29.3,40.2c-0.1,0-0.2,0.1-0.3,0.1C29.1,40.3,29.2,40.2,29.3,40.2z"
              />
              <path
                className={color0}
                d="M43.9,39.2c0.2,0.1,0.4,0.1,0.6,0.2c-0.1-0.1-0.3-0.1-0.4-0.2C43.9,39.2,43.8,39.2,43.9,39.2z"
              />
              <path
                className={color0}
                d="M44.5,39.8L44.5,39.8c0,0.1,0,0.1,0.1,0.1C44.6,39.9,44.6,39.9,44.5,39.8z"
              />
              <path
                className={color0}
                d="M19.7,53.4l0.1,0.4C19.8,53.5,19.7,53.4,19.7,53.4z"
              />
              <path
                className={color0}
                d="M23.6,44.3c0.4-0.5,0.4-0.5,0.4-0.6s0.1-0.3,0.8-0.9l-0.4,0.4c0.4-0.5,1.1-1.1,1.8-1.6
						c0.8-0.5,1.6-0.9,2.3-1.3c-0.1,0.1,0.9-0.3,0.8-0.1c0.9-0.4,1.9-0.8,2.5-1c0.3-0.1,0.8-0.2,1.1-0.3c0.4-0.1,0.9-0.3,1.3-0.3
						c-0.2,0,0.1-0.1,0.2-0.1c0.1-0.1-0.1-0.1-1.1,0.2c0.2-0.1,0.1-0.1,0.2-0.1c-0.7,0.1-1.1,0.3-1.5,0.4c-0.4,0.1-0.8,0.3-1.4,0.5
						c-0.7,0.1,1.1-0.4,0.4-0.3c-0.3,0.1-0.8,0.3-1.3,0.4c-0.3,0.1-0.4,0.2-0.7,0.3c-0.3,0.1-0.4,0.2-0.7,0.3
						c-0.1,0.1-0.2,0.1-0.3,0.1l0,0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.3,0.1-0.4,0.3-0.3,0.3
						c-0.9,0.5-1,0.6-1.3,0.8c-0.6,0.6-1.7,1.5-2.5,2.5s-1.3,1.9-1.2,2C22.5,45.9,23,45.1,23.6,44.3L23.6,44.3L23.6,44.3z"
              />
              <path
                className={color0}
                d="M41.9,38.4c-0.1,0-0.2-0.1-0.3-0.1C41.7,38.4,41.8,38.4,41.9,38.4z"
              />
              <path
                className={color0}
                d="M51.6,50.5L51.3,50c-0.4-1.4-0.9-2.3-1.3-3.1c-0.4-0.9-0.9-1.6-1.6-2.6c-0.1-0.2,0.1,0.1,0.2,0.2
						c-0.6-0.7-1.3-1.6-2.1-2.2c-0.8-0.7-1.6-1.3-2.2-1.5c-0.4-0.3-1.8-0.9-1.3-0.8c-0.3-0.1-0.6-0.2-0.9-0.3
						c-0.1-0.1,0.7,0.3,0.7,0.2c-0.3-0.1-0.3-0.1-0.4-0.1c0.2,0.1,0.1-0.1,0.3-0.1c0.1,0,0.1,0,0.4,0.1c0.2,0.1,0.5,0.2,0.9,0.4
						c0.6,0.5-1.3-0.6-0.9-0.2c0.6,0.3,0.6,0.2,0.6,0.2c0.8,0.4,0.7,0.4,0.6,0.4c0.3,0.1,1.2,0.8,1.6,0.9c1.3,1-0.1,0.1,0.7,0.8
						l0.6,0.3c0,0,0.1,0.1,0.2,0.2c0.1,0.1,0.3,0.3,0.4,0.4c0.2,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.2,0.2,0.2,0.2c0.9,1,0.8,0.9,0.9,0.8
						s0.1-0.2,1,1.1c-0.3-0.3,0.2,0.8,0.8,2.1c-0.3-0.8-1.2-2.1-1.8-3c0.4,0.7,0.1,0.3-0.3-0.3c-0.4-0.5-0.8-1-0.6-0.6
						c0.3,0.3,0.6,0.6,0.9,1.1c0.3,0.4,0.6,0.9,0.8,1.3L49.7,46c0.5,0.9,1.1,1.5,1.5,2.9c0.3,0.7,0.1,0.6,0.1,0.4
						C51.5,50,51.7,50.5,51.6,50.5z M51.1,49c0,0-0.1-0.3-0.1-0.4c-0.1-0.3-0.2-0.4-0.2-0.4C50.9,48.3,51,48.7,51.1,49z"
              />
              <path
                className={color0}
                d="M52.6,31.7c0.1,0.1,0.2,0.1,0.3,0.2C52.8,31.9,52.7,31.8,52.6,31.7z"
              />
              <path
                className={color0}
                d="M32.3,76.4h-0.6C32,76.3,32.2,76.4,32.3,76.4z"
              />
              <path
                className={color0}
                d="M19,72.5c0,0-0.2-0.1-0.4-0.3c-0.2-0.1-0.4-0.3-0.4-0.3C18.5,72.2,18.7,72.4,19,72.5z"
              />
              <path
                className={color0}
                d="M46.2,28.1L46.2,28.1c-0.1,0-0.1-0.1-0.2-0.1L46.2,28.1z"
              />
              <path
                className={color0}
                d="M9.8,47.2C9.9,47.1,10,47,10,46.7C10,46.8,9.9,47,9.8,47.2z"
              />
              <path
                className={color0}
                d="M19.1,72.6C19.1,72.5,19.1,72.5,19.1,72.6C19,72.5,19,72.5,19,72.5L19.1,72.6z"
              />
              <path
                className={color0}
                d="M38.6,76.1C38.7,76,38.9,76,39,76C38.8,76.1,38.7,76.1,38.6,76.1z"
              />
              <path
                className={color0}
                d="M37.6,75.8c0.1-0.1,0.3-0.1,0.4-0.1L37.6,75.8z"
              />
              <path
                className={color0}
                d="M53.6,62.4c0,0,0.1-0.2,0.1-0.4c0.1-0.2,0.1-0.4,0.1-0.4C53.8,61.9,53.7,62.2,53.6,62.4z"
              />
              <path
                className={color0}
                d="M39.8,75.3C39.9,75.3,39.9,75.2,39.8,75.3C39.8,75.2,39.8,75.3,39.8,75.3z"
              />
              <path
                className={color0}
                d="M56,35.7C56,35.7,56,35.6,56,35.7L56,35.7L56,35.7z"
              />
              <path
                className={color0}
                d="M57.5,36.9c0.1,0.2,0.3,0.4,0.4,0.6C57.9,37.3,57.7,37,57.5,36.9z"
              />
              <path
                className={color0}
                d="M15.9,36.9c0.2-0.1,0.6-0.5,0.4-0.3C16.6,36.2,16.4,36.2,15.9,36.9z"
              />
              <path
                className={color0}
                d="M52.4,33.9c0.1,0.1,0.3,0.1,0.4,0.3C52.5,34,52.3,33.8,52.4,33.9z"
              />
              <path
                className={color0}
                d="M52.8,34.1c0.1,0.1,0.3,0.2,0.3,0.3C53,34.3,52.9,34.2,52.8,34.1z"
              />
              <path
                className={color0}
                d="M14.1,42.2c-0.1,0.1-0.1,0.1-0.2,0.2C14,42.4,14.1,42.3,14.1,42.2z"
              />
              <path
                className={color0}
                d="M58.4,37.9c0.1,0.1,2.1,2.5,0.5,0.4c0.1,0,0.4,0.3,0.6,0.7c0.3,0.4,0.6,0.9,0.8,1.1
						c-0.3-0.4,0.3,0.1,0.2,0c-0.2-0.3-0.5-0.6-0.8-1.1c-0.1-0.3,0.4,0.4,0.8,1c-0.4-0.8,0.7,0.8,0.1-0.2c-1.5-2.1-2.7-3.5-4.1-4.6
						c1,1.1,2.1,2.3,2.8,3.3l-0.4-0.3c-0.8-1.1-1.3-1.7-2.2-2.7c0,0,0.5,0.6,0.9,1.1c0.4,0.6,0.9,1.1,0.9,1.1
						c0.8,1.2,0.2,0.4-0.5-0.4C58.2,37.7,58.4,37.9,58.4,37.9z"
              />
              <path
                className={color0}
                d="M56.3,37.2L56.3,37.2C56.4,37.3,56.4,37.3,56.3,37.2L56.3,37.2z"
              />
              <path
                className={color0}
                d="M51.2,61.5l0.1-0.3C51.2,61.5,51.2,61.5,51.2,61.5z"
              />
              <path className={color0} d="M55.5,35.5L55.5,35.5L55.5,35.5z" />
              <path className={color0} d="M48.2,66.4L48.2,66.4L48.2,66.4z" />
              <path
                className={color0}
                d="M49.1,69.5C49.2,69.5,49.2,69.5,49.1,69.5C49.3,69.4,49.2,69.5,49.1,69.5z"
              />
              <path
                className={color0}
                d="M48.2,66.5C48.2,66.4,48.2,66.4,48.2,66.5C48.2,66.4,48.2,66.5,48.2,66.5z"
              />
              <path
                className={color0}
                d="M22.3,71.9c0,0,0.1,0.1,0.2,0.1C22.6,72,22.6,72,22.3,71.9z"
              />
              <path
                className={color0}
                d="M27.6,74.2c0.1,0,0.1,0,0.1,0.1L27.6,74.2z"
              />
              <path
                className={color0}
                d="M14.6,37.6c-0.2,0.3-0.3,0.4-0.4,0.6C14.2,38,14.4,37.8,14.6,37.6z"
              />
              <path
                className={color0}
                d="M48.2,66.4c0-0.1,0.1-0.1,0.1-0.3C48.2,66.3,48.2,66.4,48.2,66.4z"
              />
              <path
                className={color0}
                d="M15.2,66.6c0.1,0.1,0.1,0.1,0.1,0.1L15.2,66.6L15.2,66.6z"
              />
              <path
                className={color0}
                d="M13.6,63.9c0.1,0.1,0.1,0.1,0.1,0.2c-0.6-0.9-1.4-2.8-1.6-3c0.1,0.4,0.9,2.1,0.6,1.9
						c0.3,0.7,0.9,1.7,1.1,2.1c-0.6-1.1,0-0.2,0.5,0.5c0.4,0.6-0.2-0.1,0.2,0.5c0,0,0.3,0.4,0.6,0.7c0.1,0.2,0.3,0.4,0.4,0.5
						c0.1,0.1,0.2,0.2,0.2,0.2s-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.3-0.3-0.5-0.6-0.5-0.6l0.4,0.3
						C15,66.5,15,66.5,15,66.5c-0.9-1-0.8-1.1-0.8-1.2C14.4,65.1,14.4,65.1,13.6,63.9z"
              />
              <path
                className={color0}
                d="M13.7,64.1c0.1,0.1,0.2,0.3,0.3,0.3C13.9,64.2,13.8,64.2,13.7,64.1z"
              />
              <path
                className={color0}
                d="M11,53.9c0-0.1-0.1-0.3-0.1-0.4C10.9,53.5,11,53.7,11,53.9z"
              />
              <path
                className={color0}
                d="M49,43c0,0.1,0.3,0.3,0.4,0.6c0.2,0.3,0.4,0.6,0.5,0.6c-0.3-0.4-0.5-0.8-0.8-1.1L49,43z"
              />
              <path
                className={color0}
                d="M25.6,30.5l-0.4,0.3l-0.1,0.1C25.2,30.8,25.5,30.6,25.6,30.5z"
              />
              <path
                className={color0}
                d="M12.6,62.6c0.1,0.3,0.2,0.4,0.3,0.4C12.7,62.9,12.7,62.7,12.6,62.6z"
              />
              <path
                className={color0}
                d="M25.8,30.6c0.2-0.1,0.5-0.2,0.6-0.2c0.4-0.2,0.7-0.4,1.1-0.6C27,30.2,26.4,30.4,25.8,30.6z"
              />
              <path className={color0} d="M27.5,29.9L27.5,29.9L27.5,29.9z" />
              <path
                className={color0}
                d="M48.4,42.3c-0.1-0.1-0.2-0.2-0.3-0.3l0.2,0.1c-0.3-0.3-0.5-0.5-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.3
						c0.1,0.1,0.1,0.1,0.1,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.2-0.9-0.6-1.6-1c-0.3-0.2-0.6-0.3-0.9-0.4s-0.5-0.3-0.6-0.3
						c-0.7-0.3-1.6-0.6-2.2-0.8c0.5,0.1,1.2,0.4,1,0.3c-0.3-0.1-0.5-0.3-0.7-0.3c-0.3-0.1-0.4-0.2-0.7-0.3c-0.1,0.1-0.3,0-0.7,0
						c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.7-0.1c0,0,0.3,0.1,0.6,0.1c0.3,0.1,0.6,0.1,0.6,0.1s-0.2,0-0.4-0.1
						c-0.3-0.1-0.6-0.1-0.9-0.1c-0.3,0-0.6,0-0.9,0c-0.3,0-0.4,0-0.4,0c-0.9-0.2,1.3-0.1,1.7,0c-0.3,0-0.5-0.1-0.8-0.1
						s-0.5-0.1-0.7-0.1c-0.3,0-0.4-0.1-0.6-0.1c-0.2,0-0.4,0-0.6,0c-0.4,0-0.8,0-1.1,0.1c-0.3,0.1-0.6,0.1-0.8,0.2
						c-0.1-0.1-0.4-0.3-1.7,0c0.9-0.1-0.8,0.3,0.1,0.2c-0.4,0.1-1.4,0.3-1.5,0.4l0.1-0.1c-0.6,0.1-1,0.3-1.4,0.4
						c-0.4,0.1-0.7,0.3-0.9,0.4c-0.5,0.3-0.9,0.5-1.3,0.8c-1.2,0.6-2.1,1.1-2.8,1.6c-0.8,0.6-1.4,1.1-2.1,1.9
						c-0.1,0-0.7,0.3-1.2,1.1c-0.1,0.2-0.4,0.7-0.6,1.3c-0.3,0.5-0.4,1.1-0.4,1.1c0.4-0.7,0.5-1,0.6-1.2c0.1-0.3,0.1-0.4,0.4-0.8
						c0.6-0.6,0.1,0,0.8-0.8c-0.3,0.3-0.8,1.1-1.3,2c-0.4,0.9-0.8,1.8-1,2.2c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.2
						c-0.3,0.7-0.5,1.1-0.7,1.6c-0.2,0.6-0.4,1.3-0.4,2.5c0,0,0,0.3,0,0.6c0,0.1,0,0.3,0,0.4s0,0.2,0,0.2c0-0.1-0.1-0.1-0.1-0.1
						c0,1,0.1,1.3,0.1,1.8c0,0.2,0.1,0.4,0.1,0.7c0.1,0.3,0.2,0.6,0.4,1.1c-0.1-0.2-0.1-0.1-0.1-0.2c0.3,1.2,1.1,2.3,1.6,3
						c0.3,0.5-0.2-0.1-0.4-0.3c0.1,0.3,0.3,0.4,0.4,0.7l0.6,0.6c0.2,0.3,0.4,0.4,0.6,0.6c0.2,0.2,0.4,0.4,0.6,0.5
						c0.8,0.6,1.6,1.1,2.3,1.4c0,0,0.3,0.2,0.6,0.4c0.1,0.1,0.3,0.2,0.4,0.3s0.2,0.1,0.2,0.1c-0.4-0.1-0.8-0.3-1.1-0.4
						c-0.3-0.1-0.5-0.2-0.4-0.1c0.4,0.2,0.5,0.3,0.7,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.3,0.1,0.6,0.3c-0.2,0-0.6-0.1-1.1-0.3
						c0.5,0.3,0.8,0.4,0.9,0.5c0.2,0.1,0.3,0.1,0.3,0.1c0.1-0.1,0-0.1,0.8,0.3c0.3,0.1,0.6,0.2,0.8,0.3c0.2,0,0.3,0,0.4,0
						c0,0,0.3,0.1,0.6,0.3c0.1,0.1,0.3,0.1,0.4,0.1s0.2,0.1,0.2,0.1c0.2-0.1,1.8,0.2,2.8,0.1c0.1-0.1-0.8-0.1-1.3-0.1
						c0.1-0.1,1.2-0.1,1.9-0.2c0.1,0.1,0.4,0,0.8,0c0.2,0,0.4-0.1,0.7-0.2s0.5-0.1,0.8-0.3c0.9-0.3,1.7-0.9,1.4-0.5
						c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.3,0.5-0.4c0.2-0.2,0.3-0.3,0.5-0.5c0.6-0.6,1.4-1.4,1.8-2.1
						c0.8-0.9,1.7-2.8,1.9-3.9c0.1-0.3,0.3-1.3,0.3-1.6s0.1-0.5,0.1-0.1c0.1-1.3,0.1-2.3-0.2-3.3c-0.2-0.9-0.6-1.8-1.2-2.8
						c-0.1-0.1,0.1,0.1,0.3,0.4s0.3,0.4,0.4,0.4c-0.1-0.2-0.3-0.3-0.3-0.4c-0.1-0.2-0.2-0.3-0.3-0.5s-0.2-0.3-0.3-0.5
						s-0.3-0.3-0.4-0.5c-0.3-0.4-0.6-0.6-1-1c-0.2-0.2-0.9-0.4-1.1-0.5s-0.5-0.4-0.8-0.4c0.4,0.2,0.5,0.3,0.6,0.3
						c0,0.1-0.3-0.1-0.6-0.3c-0.3-0.1-0.8-0.3-1.1-0.3c-0.2-0.1-0.1,0,0.1,0c0.1,0,0.3,0.1,0.2,0c-0.6-0.2-1.2-0.3-1.8-0.3
						s-1.2,0.1-1.7,0.3c-1.1,0.3-2.1,0.9-3,1.6c-0.8,0.7-1.6,1.6-2,2.6c-0.3,0.5-0.4,1-0.5,1.6s-0.1,1.1,0.1,1.7
						C29.1,52.5,29,52,29,51.7c0-0.3-0.1-0.4-0.1-0.4s0,0.1-0.1,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2,0,0.2
						c0.1,0.5,0.4,1.5,0.9,2.1c0,0.1,0.3,0.4,0,0.1c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.2,0.3,0.3,0.5,0.5l0,0c0.8,0.6,1.7,0.8,2.6,0.9
						c0.3,0.1,0.5,0.1,0.8,0.1s0.5,0,0.8,0s0.6-0.1,0.8-0.1h0.2c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.3-0.1,0.5-0.1c0,0-0.3,0.1-0.6,0.3
						c-0.4,0.1-0.8,0.2-1.3,0.3c-0.3,0.1-0.5,0-0.8,0c-0.3,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.4-0.1c-0.1,0-0.2-0.1-0.2-0.1l1,0.3
						c0,0-0.1,0-0.3-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.3-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.1-0.3-0.1c1,0.5,2,0.4,2.1,0.6
						c-0.3,0-0.8-0.1-0.8-0.1c0.5,0.2,0.8,0.1,1.1,0.2c0.3,0,0.5-0.1,0.9-0.1c-0.6,0.1-1.4,0.1-2.2-0.1c-0.8-0.2-1.7-0.5-2.5-1l0,0
						l0,0c-0.6-0.5-1-1-1.4-1.6c-0.3-0.6-0.6-1.2-0.7-1.7c-0.1-0.4,0.1,0.3,0.2,0.4c-0.1-0.3-0.1-0.4-0.1-0.6c0-0.2-0.1-0.4,0-0.6
						c0-0.4,0.1-0.9,0.2-1.4c0.1-0.4,0-0.3-0.1,0.1c-0.1,0.1-0.1,0.4-0.1,0.6c0,0.2-0.1,0.4-0.1,0.6c-0.1-0.1-0.1-0.1-0.1-0.3
						s0.1-0.4,0.1-0.8c0.1-0.1,0.3-0.9,0.9-1.8c0.5-0.9,1.3-1.6,1.7-1.8c0.6-0.6,1-0.8,1.1-0.9c0.1-0.1,0.1-0.1-0.1-0.1
						c-0.1,0-0.2,0.1-0.1-0.1c0.1-0.1,0.4-0.3,1.3-0.7c0,0,0.1,0,0.1-0.1c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.5-0.1,0.5-0.1
						c0.4-0.2,1-0.3,1.6-0.4c0.6-0.1,1.2-0.1,1.8,0s1.2,0.3,1.8,0.5l0.1,0.1h0.1l0,0l0,0h0.1l0.2,0.1l0.4,0.3
						c0.3,0.2,0.9,0.3,1.1,0.5c0,0-0.1-0.1-0.2-0.1C40.6,46,40,46,40,46c-0.3-0.1,0.6,0.2,0.8,0.4c0.3,0.3,0.6,0.6,0.9,0.8
						c0.3,0.3,0.5,0.6,0.8,0.9c0.4,0.6,0.9,1,1,1.5c0.5,0.8,0.9,2.1,1.1,3.5c0.1,0.7,0.1,1.3,0.1,2c-0.1,0.6-0.2,1.4-0.3,1.9
						c-0.2,0.3,0.1-0.6,0.1-1.4c0.1-0.9,0.1-2-0.1-2.5c0,0,0,0.1,0,0.3s0.1,0.4,0,0.7c0,0.5-0.1,1-0.1,1c0-0.6,0.1-1.1,0-1.4
						c-0.1-0.4-0.1-0.7-0.2-1c0,0.4,0.1,0.8,0.1,1.1s0,0.6,0,0.9c0,0.3,0,0.6,0,0.9c-0.1,0.3-0.1,0.8-0.2,1.2c0-0.1-0.1,0.1-0.3,0.8
						l0.1,0.3c0,0-0.1,0.1-0.1,0.3c0,0-0.1,0.5-0.1,0.8c-0.1,0.3-0.2,0.3-0.3,0.6s-0.3,0.7-0.4,0.9c-0.1,0.3-0.2,0.4-0.2,0.4
						c0.3-0.5,0.4-0.5,0.8-1.2c-0.1,0.1-0.1,0,0-0.2c-0.1,0.1,0.1-0.3,0.3-0.9c-0.1,0.6,0.3-0.7,0.4-0.7c-0.1,0.4-0.3,0.8-0.4,1.1
						c-0.2,0.4-0.3,0.8-0.5,1.1c-0.4,0.7-1.1,1.3-1.4,1.8l-0.3,0.2c-0.2,0.3-0.5,0.6-0.7,0.9l-0.1-0.4l-0.2,0.1l0,0l-0.1,0.1l0,0
						l-0.1,0.1c-0.1,0.1-0.3,0.3-0.4,0.4c-0.4,0.5-1,0.9-1.4,1.2l0.3-0.3c-1.5,1.1-3.1,1.7-4.8,1.8c-0.2,0.1-0.4,0.1-0.6,0.1
						c-0.2,0-0.4,0.1-0.6,0.1s-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.6,0s-0.4,0-0.6,0s-0.4-0.1-0.6-0.1c-0.4-0.1-0.8-0.1-1.1-0.1
						c-0.2,0-0.6-0.1-1-0.3c-0.4-0.2-1.1-0.4-1.6-0.7c-0.6-0.3-1.2-0.6-1.7-0.9c-0.5-0.4-1-0.6-1.4-0.9c-0.1-0.1,1.1,0.6,1.6,0.9
						c-0.5-0.3-0.9-0.6-1.3-0.8s-0.6-0.4-0.7-0.6c2.1,1.7-0.1-0.2,0.9,0.5c-1-0.8-1.9-1.6-2.4-2.1c0.1,0.1,0.1,0.2,0.2,0.3l0.1,0.1
						l0.1,0.1l0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0-0.1-0.1c-0.1-0.1-0.3-0.2,0.4,0.5c-0.1-0.1-0.3-0.3-0.5-0.4l-0.1-0.1
						L22,61.8h-0.1l0,0l0,0l0,0l-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.3-0.3-0.4-0.5s-0.3-0.4-0.4-0.6
						c-0.6-0.8-1.1-1.8-1.4-2.7c0.1,0,0-0.3-0.2-0.9L18.8,56c-0.2-0.9,0.5,1.6,0.1-0.1c-0.2-0.4-0.3-1-0.3-1.6
						c0-0.3-0.1-0.6-0.1-0.9V53v-0.1v-0.1v-0.1c0-0.1,0-0.3,0-0.4s0-0.3,0-0.4s0-0.3,0.1-0.4c0-0.3,0.1-0.6,0.1-0.8
						c0.1-0.5,0.1-0.9,0.1-1.1c0.3-0.7,0.1,0,0,0.6s-0.1,1.1,0.1,0.1c-0.1,0.6-0.1,1.4-0.2,1.9c0,0.3,0,0.6,0,0.8
						c0,0.3,0.1,0.4,0.1,0.6c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3s0-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.1-0.4c0,0,0-0.3,0-0.6
						s0-0.7,0-0.7l-0.1,0.3c0.1-1.1,0.3-2.5,0.9-4c0.5-1.4,1.3-2.8,2.2-4c0.7-1,1.3-1.8,2.1-2.6c0.8-0.8,1.7-1.5,3.1-2.1
						c-0.2,0.1-0.1,0.1,0,0.1c0.1-0.1,0.2-0.1,0.3-0.1s0.2-0.1,0.3-0.1c0.4-0.1,0.8-0.3,0,0.2c0.3-0.1,0.6-0.3,0.9-0.3
						c0.3-0.1,0.6-0.2,0.8-0.3c0.4-0.2,0.8-0.3,0.8-0.4c-1.1,0.4-0.4,0.1-1.3,0.4c0.6-0.3,2-0.8,2.6-0.9c-0.3,0.1-0.2,0.1-0.3,0.1
						c1.3-0.3,0.6-0.3,1.6-0.5c0.3,0,2.1-0.4,3.1-0.4c0,0,0.1,0,0.2-0.1c0.1,0,0.3-0.1,0.4-0.1c0.4-0.1,0.7-0.1,0.7-0.1
						c-0.4,0.1-0.1,0.1,0.4,0.1h0.1h0.1H38h0c0.1,0,0.2,0,0.3,0.1c0.2,0,0.4,0.1,0.4,0.1c0.4,0,0.9,0.1,1.4,0.1
						c0.3,0,0.5,0.1,0.8,0.1s0.6,0.1,1,0.3c-0.3-0.1,0,0.1,0.4,0.2c0.4,0.1,0.8,0.4,0.8,0.4c0.6,0.3,1.1,0.5,1.6,0.8
						s0.9,0.4,1.4,0.8c0.9,0.5,1.8,1.1,2.6,2c-0.1-0.1-0.4-0.3,0,0.2c0.2,0.2,0.4,0.4,0.8,0.8c0.3,0.3,0.5,0.6,0.7,0.8l0,0l0,0
						c0.2,0.2,0.3,0.4,0.3,0.4c0.3,0.4,0.6,0.6,0.9,1.1c0.3,0.4,0.7,0.9,1.2,1.8c0.1-0.2-0.8-1.6-1.3-2.3c0,0-0.1-0.1-0.3-0.3
						c0-0.5-0.1-0.6-0.1-0.6c-0.2-0.6-1.1-1.4-1.4-1.8c0.5,0.4,1.3,1.3,2.1,2.3c0.8,1.1,1.4,2.3,1.9,3.2c-0.2-0.6-0.6-1.6-1.2-2.6
						c-0.6-1-1.4-2.1-2.2-3l0.6,0.9c0,0-0.2-0.3-0.4-0.5c-0.1-0.1-0.2-0.3-0.3-0.3c-0.1-0.1-0.1-0.1-0.1-0.1s0.2,0.3,0.4,0.5
						c0.2,0.3,0.4,0.5,0.4,0.5c-0.8-0.9-1.5-1.7-2.4-2.4c1,0.9,1.2,1.3,0.7,0.8c-1-1.1-1.2-0.9-2-1.6c0.5,0.4,1.1,0.9,0.6,0.6
						c-0.4-0.4-0.8-0.6-1.1-0.9c-0.3-0.3-0.6-0.4-0.9-0.6c-0.6-0.4-1.1-0.6-2-1.1c0.1,0.1-0.5-0.1-1.1-0.3c-0.3-0.1-0.7-0.2-0.9-0.3
						c-0.3-0.1-0.5-0.1-0.6-0.1c0,0,0.3,0.1,0.4,0.1s0.4,0.1,0.4,0.1s-0.1,0-0.3-0.1c-0.2,0-0.4-0.1-0.6-0.1
						c-0.3-0.1-0.5-0.1-0.6-0.1c-0.2,0-0.3,0-0.3,0c-1.8-0.4,1.6,0.1-1.1-0.4c-0.3,0-0.6-0.1-0.8-0.1c-0.1,0-0.2,0-0.3,0h-0.1h-0.1
						c-0.1,0-0.1,0-0.1,0c-0.4,0-0.7,0.1-0.9,0.1c-0.6,0.1-1,0.2-1.9,0.3c0.3-0.1,0.8-0.3,1.5-0.3c0.3-0.1,0.7-0.1,1.1-0.1h0.3h0.1
						h0.1h0.1H38c0.2,0,0.4,0,0.6,0.1c0,0-0.2,0-0.4-0.1h-0.1H38h-0.1c-0.1,0-0.1,0-0.2,0s-0.1,0-0.1,0c0.1,0,0.2,0,0.3,0H38l0,0
						h0.1h0.1c0.1,0,0.1,0,0.2,0c0.3,0,0.6,0.1,0.9,0.1c0.4,0.1,0.8,0.1,1.2,0.2s0.7,0.1,1,0.2c-1.8-0.3,0.8,0.2,1.6,0.6
						c0.2-0.1-0.9-0.4,0.4-0.1c-1.3-0.4-1.4-0.5-3-0.8c0.4,0.1,0.8,0.2,1.1,0.3s0.6,0.1,0.9,0.2c0.5,0.1,0.9,0.2,1.3,0.3
						c0.4,0.1,0.8,0.2,1.1,0.4c0.2,0.1,0.4,0.2,0.8,0.3c0.3,0.1,0.6,0.3,0.9,0.5c-1.3-0.7,0.4,0.4,0.4,0.6c0.6,0.4,1.7,1.2,2.1,1.6
						l-0.3-0.4c0.4,0.3,0.6,0.6,0.9,0.8c0.3,0.3,0.4,0.5,0.6,0.8c0.4,0.5,0.8,1.1,1.5,1.8c0.1,0.1-0.1-0.3-0.4-0.6
						c-0.3-0.4-0.6-0.7-0.4-0.6c1,1.1,1.5,1.9,1.9,2.8c0.2,0.4,0.4,0.8,0.6,1.3c0.1,0.3,0.2,0.4,0.3,0.8c0.1,0.3,0.2,0.6,0.3,0.8
						c0.1,0.1,0.2,0.1,0.6,0.9c-0.2-0.3,0,0.4-0.4-0.5c0.1,0.4,0.3,0.8,0.4,1.3c0.1,0.4,0.3,0.8,0.4,1.2c-0.2-0.9-0.1-0.7,0.1-0.3
						c0.1,0.4,0.3,1.1,0.4,1.3c0.1,0.9-0.1-0.3-0.3-0.8c0.1,0.7,0.1,1.6,0.4,2c-0.1,0-0.2-0.5-0.4-1.1c-0.1-0.3-0.2-0.7-0.3-1.1
						c-0.1-0.4-0.3-0.8-0.3-1.1l0.3,0.9c-0.3-0.6-0.2-0.7-0.5-1.6c0.3,0.8,0.1,0.8-0.1,0.6c0-0.1,0.1,0.1,0-0.1
						c-0.2-0.7-0.4-1.2-0.4-1c-0.4-0.6,0.3,0.9,0.3,1.4c0.1,0.3,0.3,0.7,0.3,1c0,0,0.3,0.9,0.6,1.8c0.1,0.4,0.2,0.9,0.3,1.3
						c0.1,0.2,0.1,0.3,0.1,0.4s0,0.2,0,0.2c0.1,1.6-0.1,2.3-0.3,3.5l0.1,0.3c-0.2,1.4-0.6,2.6-0.5,1.8c0.1-0.1,0.1-0.3,0.1-0.5
						c0-0.1,0-0.1,0-0.2V59c-0.1,0.4-0.1,0.7-0.2,1c-0.1,0.3-0.1,0.6-0.2,0.8c-0.1,0.4-0.2,0.6-0.2,0.7c0.6-2.5-0.1-0.2-0.3-0.6
						c0,0,0,0.2-0.1,0.4c-0.1,0.3-0.2,0.5-0.3,0.8s-0.3,0.6-0.3,0.8c-0.1,0.2-0.1,0.4-0.1,0.4c0.6-1.4,0.1,0-0.1,0.5
						c-0.1,0.1-0.3,0.5-0.6,1s-0.6,1.1-0.9,1.4V66c-0.3,0.3-0.5,0.8-0.9,1.2c-0.2,0.3-0.4,0.5-0.5,0.7c-0.2,0.3-0.4,0.4-0.5,0.6
						c0,0-0.1,0.3-0.3,0.6c-0.3,0.3-0.5,0.8-0.7,1c1.3-1.3,0.6-0.4,0.7-0.5c-0.1,0.1-0.4,0.4-0.4,0.4c-0.1,0.2,0.3-0.3,0.3-0.3
						c1.3-1.4,2.4-3,2.6-3.7c-0.4,0.4-0.4,0.8-0.9,1.3c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.3-0.4,0.4-0.7c0.1-0.1,0.2-0.3,0.3-0.4
						s0.2-0.3,0.3-0.4c0.2-0.3,0.4-0.6,0.6-0.9l0.3-0.4c0,0,0.1-0.1,0.1-0.3c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.4,0.3-0.8,0.3-0.8
						c-0.1,0.3-0.1,0.4-0.1,0.6c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.2-0.2,0.4-0.4,0.8c0.1-0.1,0.3-0.4,0.5-0.9s0.4-1.1,0.6-1.5l0.1-0.6
						c0.1,0.3,0.6-1.9,0.3-0.4c0,0,0,0.2-0.1,0.4c-0.1,0.3-0.2,0.6-0.3,0.8c-0.1,0.3-0.2,0.6-0.3,0.8c-0.1,0.3-0.1,0.4-0.1,0.4
						l0.4-0.6c0,0-0.2,0.5-0.4,1c-0.3,0.5-0.5,1-0.5,1l0.2-0.5c-0.2,0.4-0.4,0.7-0.5,0.9c-0.1,0.3-0.3,0.4-0.3,0.6
						c-0.1,0.1-0.1,0.1-0.1,0.2C52,65.8,52,65.8,52,65.9C51.9,66,51.9,66,51.9,66c-0.1,0.1-0.2,0.2-0.4,0.4
						c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.3-0.4,0.5-0.6,0.9c-0.6,0.9-1.3,1.9-2.8,3.3c1.1-1.1,0.1-0.2,0.4-0.6c-1.1,1-1.5,1.4-2.1,2
						c-0.2,0.1-0.3,0.2-0.3,0.1s0.3-0.2,0.8-0.6c0-0.1-0.3,0.2-0.7,0.5C45.4,72.7,45,73,44.8,73c-1.9,1.3-2.7,1.8-3.6,2.3
						c-0.4,0.3-0.9,0.5-1.6,0.8c-0.4,0.1-0.8,0.3-1.3,0.4c-0.3,0.1-0.5,0.1-0.8,0.2s-0.6,0.1-0.9,0.2l0.2-0.1
						c-1.7,0.4-3,0.5-4.3,0.5c-1.3,0-2.6-0.1-4.2-0.3c0,0-0.6-0.2-1.2-0.3c-0.6-0.2-1.2-0.4-1.2-0.4c0.3,0.1,0.8,0.2,1.1,0.2
						c0.4,0.1,0.6,0,0.3-0.1c1.1,0.4,2.4,0.6,3.7,0.8c0.1-0.1-0.4-0.1-0.6-0.2c0.8,0.1,1.2,0.2,2.2,0.1c-0.1,0-1.2-0.1-0.3-0.1
						c0,0,0.4,0,0.7,0.1c0.4,0,0.7,0.1,0.7,0.1c0.9-0.1,1.1-0.2,1.4-0.3c0.3-0.1,0.6-0.1,1.6-0.4c-0.4,0.2-1.2,0.5-2,0.6
						c1-0.1-1.3,0.2-0.4,0.2c1.4-0.3,2.1-0.3,4-0.9c-0.2,0.1-0.3,0.1-0.8,0.3c0,0,0.7-0.2,1.4-0.4c0.3-0.1,0.7-0.2,0.9-0.3
						c0.3-0.1,0.4-0.1,0.4-0.1c-0.3,0.1,0.2-0.2,0.8-0.4c0.6-0.3,1.2-0.6,1-0.6c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.4,0.2-0.6,0.3
						c-0.4,0.1-0.6,0.3-0.9,0.4c-0.6,0.2-1.1,0.4-2.8,0.8c0.9-0.2,2.1-0.7,2.6-1c0,0,0.1,0,0.2-0.1c0.4-0.3-0.1-0.1-0.1-0.4
						c-0.3,0.2-0.7,0.3-1,0.4s-0.6,0.2-0.9,0.3c-0.6,0.2-1.3,0.3-2.2,0.5c-0.3-0.5,0-0.5,0.4-0.5c0.4-0.1,0.9-0.2,1.4-0.3
						c0,0-0.4,0.1-0.8,0.2c-0.4,0.1-0.8,0.2-0.8,0.2L36,76c0,0-0.3,0.1-0.6,0.1c-0.4,0.1-0.9,0.1-1.4,0.1c-0.5,0.1-1,0.1-1.4,0.1
						c-0.4,0-0.6,0-0.6,0c0.8,0,1-0.1,1.4-0.2c-0.5,0-1,0-1.6-0.1c0.1,0.1,0.1,0.2-0.4,0.2c-0.6,0-1.2-0.1-1.7-0.2s-0.9-0.2-1.3-0.3
						c-0.4-0.1-0.7-0.2-1-0.3c-0.3-0.1-0.6-0.2-0.8-0.3c-0.1,0-0.2-0.1-0.3-0.1c0.4,0.3-1.8-0.3-2.6-0.6c0.1-0.1-1.2-0.6-1.8-1
						c-0.4-0.1-0.1,0.4,0.8,0.9c0.8,0.3,1.1,0.4,1.2,0.4s0.2,0,0.6,0.1c0,0,0.2,0.1,0.4,0.1s0.6,0.2,0.9,0.3
						c0.3,0.1,0.7,0.2,0.9,0.3s0.4,0.1,0.4,0.1l-0.6-0.1c1.3,0.4,2.7,0.4,2.6,0.6c-0.2,0-0.4,0-0.6-0.1c-0.3-0.1-0.4-0.1-0.7-0.1
						c-0.5-0.1-0.9-0.2-1.3-0.3c0,0,0.3,0.1,0.4,0.1c0.3,0.1,0.4,0.1,0.4,0.1c-1.6-0.3-0.8-0.3-2.7-0.9c0.3,0.2,0.3,0.2,0.1,0.1
						c-0.3-0.1-0.6-0.3-1.1-0.4c0.3,0.2,1.1,0.5,1.8,0.7c0.7,0.3,1.3,0.4,0.9,0.4c-0.3-0.1-0.6-0.1-0.9-0.3
						c-0.4-0.1-0.8-0.3-1.2-0.5c-0.8-0.4-1.7-0.8-2.3-1.1H22c-1.8-0.9-2.4-1.3-3.1-1.7c-0.7-0.6-1.6-1.3-2.4-1.8h0.1
						c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.3-0.1-0.4-0.2c-0.3-0.3-0.5-0.4-0.5-0.4c0.3,0.2,0.6,0.4,0.9,0.8c0.3,0.3,0.7,0.7,1.1,1
						l-0.1-0.2c0.9,0.9,1.8,1.3,2.7,1.7c-0.6-0.3-1.5-0.9-2.2-1.5s-1.3-1.1-1.5-1.1c0,0-0.1-0.2-0.3-0.4c-0.1-0.2-0.3-0.4-0.3-0.4
						l-0.3-0.3c-0.4-0.6,0.2-0.1,1.1,0.8c0.1,0.2,0.3,0.3,0.2,0.3c0.3,0.2,0.5,0.3,0.8,0.6c0.3,0.3,0.8,0.5,1.3,1
						c0,0-0.2-0.1-0.3-0.2c-0.2-0.1-0.3-0.2-0.3-0.2c1.4,1.1,2.9,1.9,3.5,2.1c-2.3-1.3-4.3-2.9-6-4.7c-0.3-0.2-0.7-0.4-1-0.6
						c-0.1-0.1-0.1-0.1-0.3-0.1l-0.1-0.1l0,0l0,0l0,0l0,0L14.3,68l0,0l0,0l0,0l0,0c-0.1,0-0.2-0.1-0.3-0.2c-0.5-0.6-1.1-1.3-1.6-2.2
						c-0.3-0.4-0.6-0.9-0.8-1.3c-0.2-0.4-0.4-0.8-0.5-1.1l0.1,0.1c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.3-0.6-0.4-0.8
						c-0.2-0.8,0.3,0.4,0.1-0.1c0,0-0.3-0.6-0.4-1.1c-0.1-0.3-0.2-0.6-0.3-0.8c-0.1-0.2-0.1-0.4-0.1-0.4c0.1,0,0.1,0.2,0.2,0.4
						s0.2,0.5,0.3,0.8c0.3,0.6,0.5,1.3,0.7,1.8c-0.1-0.2-0.2-0.5-0.2-0.4c0.4,1.1,1.4,2.8,1.6,3.3c0.3,0.5-0.7-0.9,0.3,0.8
						c0.2,0.3,0.3,0.5,0.4,0.7c0.1,0.1,0.3,0.3,0.3,0.4c0.2,0.2,0.3,0.3,0.4,0.3c0,0-0.3-0.4-0.4-0.7c-0.2-0.3-0.4-0.6-0.1-0.4
						c-0.3-0.4-0.4-0.7-0.7-1c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0.2,0.4,0.6,0.9,0.8,1.3
						c0.3,0.4,0.6,0.8,0.6,1.1c1.2,1.3,1.3,0.9,0.8,0.3c0,0.1-0.5-0.5-0.8-1s-0.5-0.7,0.1,0.4c-0.4-0.5-0.9-1.2-1.4-1.9
						c-0.4-0.8-0.8-1.5-1.1-2.1H11l-0.6-1.7c0.1,0.3,0.3,0.8,0.4,0.7c0,0-0.3-0.6-0.5-1.1c-0.2-0.6-0.4-1.2-0.4-1.2
						c0.5,1.3-0.2-0.9-0.2-1.5C9.5,57,9.3,56.1,9.4,57c0.1,1.1,0.1,0.6,0.4,1.5c0,0-0.1-0.3-0.3-0.6c-0.1-0.2-0.1-0.3-0.1-0.4
						c-0.1-0.1-0.1-0.2-0.1-0.2v-0.1L9,56.4c0-0.9,0-0.7,0.2-0.6c0.1,0.1,0.1,0.1,0.1,0s-0.1-0.4-0.1-0.9L9,55.2
						c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.8,0.1-1.5,0.1-2.2v-0.1v-0.1v-0.1v-0.1v-0.3c-0.1-0.1,0-0.2,0-0.4c0-0.3,0.1-0.7,0.1-1
						c0.1-0.7,0.3-1.3,0.3-1.8c0,0.6,0.1,0.8,0.3,0.1c-0.1,0.4-0.3,1-0.4,1.7c-0.1,0.4-0.1,0.7-0.1,1c0,0.2,0,0.3-0.1,0.4
						c0,0.1,0,0.1,0,0.3v0.1v0.1v0.1c0,0.1,0,0-0.1-0.1c0-0.1,0-0.2,0-0.3s0-0.3,0-0.4c0-0.5,0.1-1.4,0.3-2
						c-0.1,0.1-0.2,0.4-0.3,0.8c0.1,0.2,0,0.4,0,0.6s0,0.4,0,0.6s0,0.4,0,0.5v0.1l0,0v0.1l0,0v0.1c0,0.1,0,0.1,0,0.3
						c0,0.3,0,0.4-0.1,0.5c0,0.8-0.1,1.4,0,1.8C9,53.8,9,54,9.1,54.3c0.1,0.6,0.1,1,0.3,2.4c0.1,0,0.2,0.8,0.3,0.9
						c0.1-0.4-0.1-1.3-0.3-2.4c-0.1-1-0.3-2.2-0.2-3.1c0,0.2,0,0.5,0.1,0.6c0-0.6,0-1.1,0-1.5c0-0.1,0-0.2,0-0.3v-0.1v-0.1l0,0v-0.1
						v-0.1c0-0.2,0-0.4,0-0.6V50c0-0.8,0.1-1,0.1-1.2c0.1-0.2,0.1-0.3,0.3-0.7c0-0.2,0.1-0.6,0.2-0.9c0-0.2,0.1-0.5,0-0.4
						c0,0,0.1-0.4,0.3-0.8c0.2-0.4,0.3-0.8,0.3-0.8h-0.2c0.1-0.4,0.3-0.7,0.4-0.9c0.1-0.3,0.2-0.5,0.4-0.9l0.1-0.1
						c0,0-0.1,0.1-0.2,0.3s-0.2,0.5-0.3,0.8s-0.2,0.6-0.3,0.7c-0.1,0.2-0.1,0.4-0.1,0.4c0.4-1.1,0-0.4,0.4-1.6
						c-0.4,1.4,0.4-0.9,0.6-1c0,0,0.1-0.3,0.2-0.4s0.2-0.4,0.2-0.4v0.2c0.4-0.6,0.4-0.8,0.9-1.6c-0.3,0.4-0.6,0.8-0.8,1.2
						c-0.3,0.4-0.4,0.9-0.7,1.4c0.3-0.9,1.2-2.6,1.4-3c0.5-0.9,1-1.4,1.6-2.2c-0.5,0.8-0.3,0.7-0.3,0.8s-0.1,0.4-1.1,1.9
						c0-0.1,0.2-0.3,0.3-0.4c-0.2,0.2-0.5,0.6-0.8,1.1c-0.2,0.5-0.5,1.1-0.6,1.4c0.2-0.1-0.2,0.6,0.5-0.6c-0.3,0.4,0.4-1.1,1.1-2.2
						l0.1,0.3c0,0,0.3-0.4,0.6-0.8c0.3-0.4,0.6-0.8,0.6-0.8L14.3,39c0.5-0.5,1.2-1.6,2.1-2.6c0.1-0.2,0.4-0.5,0.8-0.8
						c0.3-0.3,0.7-0.7,1-0.9c0.3-0.3,0.5-0.5,0.7-0.6C19,34,19,34,18.9,34s-0.1,0-0.1,0.1s-0.1,0.1-0.2,0.1c0.1-0.1,0.3-0.2,0.4-0.3
						c0.1-0.1,0.3-0.2,0.3-0.3c0.2-0.2,0.4-0.3,0.6-0.5c0.4-0.4,0.9-0.8,1.4-1.1c0,0-0.4,0.2-0.7,0.4c-0.2,0.1-0.3,0.2-0.4,0.3
						C20,32.8,20,32.9,20,32.9c0.3-0.3,0.6-0.5,0.8-0.7c0.2-0.1,0.3-0.3,0.4-0.3c0.3-0.2,0.4-0.4,1-0.9c0.2-0.1,0.5-0.2,0.3,0.1
						c0.6-0.3,1.1-0.7,1.8-1.1c0.6-0.3,1.3-0.7,1.9-1s1.3-0.6,1.8-0.8c0.6-0.2,1-0.4,1.4-0.6c1.8-0.5,2.1-0.4,4.5-1
						c0.5-0.1-0.4-0.1,1.3-0.3c0.1,0-1.6,0.3,0.3,0.1c0.6-0.2,1.4-0.1,2.3-0.2c0.4,0,0.9,0,1.3,0.1c0.4,0,0.9,0,1.3,0
						c0.9,0.2,2.5,0.4,2.1,0.4c-0.7-0.1-1.6-0.3-2.3-0.3c-0.8,0-1.3,0-1.5,0c0.2-0.1-1-0.1,0.1-0.1c-0.6-0.1-0.9-0.1-1.2,0
						c-0.3,0-0.4,0-0.6,0.1c-0.3,0.1-0.6,0.1-1.6,0.1c-0.1,0.1,1,0,1.4-0.1c0,0.1-3.8,0.5-2.1,0.5c0.5-0.1,0.9-0.1,1.3-0.2
						c0.4-0.1,0.7-0.1,1-0.1c0.3-0.1,0.6-0.1,1-0.1c0.4,0,0.8,0,1.2,0.1c-0.4,0.1-0.8,0-1.3,0.1s-1,0.1-1.4,0.2H38
						c0,0,0.3-0.1,0.6-0.1c0.3,0,0.6-0.1,0.6-0.1h-0.1c0.6,0.1,1.1,0.1,1.5,0.1c0.4,0.1,0.7,0.1,0.9,0.1c0.3,0,0.6,0,0.9,0
						c0.4,0,0.8,0.1,1.4,0.2c-1.3-0.4,0.4-0.2-0.6-0.5c1.6,0.3,2.7,0.7,2.6,0.9c-1.7-0.3-1.8-0.2-1.8-0.1s0,0.1-1.4-0.1
						c0.4,0.1-0.7,0.1,0.9,0.4c0.9,0.1,0.8,0.1,0.6,0c-0.1,0-0.1-0.1-0.1-0.1c0.1,0,0.3,0.1,0.7,0.2c0.1,0.1,0.2,0.1,0.1,0.1
						c0.4,0.1,0.6,0.2,0.9,0.3c0.3,0.1,0.5,0.2,1,0.3l-0.3-0.1c0.6,0.3,0.9,0.4,1.2,0.5s0.6,0.2,1.1,0.5c-0.3-0.3,0.7,0.3,1.3,0.5
						c-0.4-0.3-0.8-0.5-1.3-0.8c-0.4-0.3-1.1-0.6-2.1-1c-0.3,0-0.1,0.1-1-0.1c0,0,0.3,0.1,0.7,0.2c0.3,0.1,0.6,0.3,0.6,0.3
						c-0.3-0.1-0.5-0.2-0.8-0.3l-1.3-0.4c1.6,0.4,0.9,0.1,1.7,0.3c1.4,0.6,1.3,0.4,2.1,0.9c-0.1,0.1,0.6,0.4,1.4,0.9
						c0.1,0.1,0.3,0.1,0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.3c0.2,0.1,0.4,0.3,0.6,0.4c0.4,0.3,0.9,0.7,1.2,0.9c-0.3-0.2-0.5-0.4-0.7-0.5
						c-1.1-0.7,0.7,0.6,0.7,0.7c0,0,0.2,0.1,0.4,0.3c0.2,0.1,0.4,0.3,0.4,0.3c-0.1-0.1-0.4-0.3-0.1,0.1c0.5,0.4,0.8,0.6,1,0.8
						s0.5,0.3,0.9,0.8c0,0.1-0.9-0.5-0.3,0.1c0.1,0.1,0.3,0.4,0.5,0.7c0.3,0.4,0.7,0.8,1.3,1.4c0.1,0.1,0.4,0.4,0.8,0.8
						c-0.1-0.1-0.1-0.1-0.1-0.1l0.6,0.9c-0.4-0.3-0.6-0.7-0.9-1.1s-0.6-0.8-1-1.1h0c-0.5-0.5-1.3-1.2-1.6-1.4
						c0.4,0.4,0.6,0.6,0.9,0.9c0.3,0.3,0.6,0.5,0.9,0.9c0.1,0.2-0.6-0.4-0.7-0.5l0.2,0.1c-1-1.1-1.6-1.5-3-2.7
						c-0.2-0.2-0.1-0.1,0-0.1c-0.6-0.4-1.2-0.9-1.8-1.3c-0.3-0.2-0.6-0.4-0.9-0.6c-0.3-0.2-0.6-0.3-1-0.4l0.4,0.2
						c-0.9-0.4-2.7-1.3-4.5-1.6v0.1c0.2,0.1-0.5-0.1-0.9-0.3l0,0c-0.4-0.1-0.9-0.1-1.4-0.3c-0.4-0.1-0.8-0.1-1.2-0.1
						c0.3,0.1,0.5,0.1,0.9,0.2c0.3,0,2,0.4,1.8,0.3c0.8,0.3,2,0.6,2.5,0.9c-0.9-0.5,0.8,0.2,0.9,0.2c0.3,0.2,1.4,0.8,1.3,0.8
						c0.2,0.1-0.4-0.3-0.9-0.5c-1.1-0.4,1.8,1.1,0.5,0.5c0.4,0.2,0.8,0.4,1.3,0.6c0.4,0.3,0.9,0.6,1.3,0.9c0.8,0.6,1.7,1.3,2.4,1.8
						c0,0-0.2-0.2-0.4-0.4s-0.4-0.4-0.4-0.4c0.6,0.5,2.1,1.9,2.1,2c-0.4-0.4-0.9-0.9-1.6-1.4c-0.1,0.1,1.4,1.3,1.4,1.4
						c0,0,0.4,0.4,0.9,0.8c0.4,0.4,0.8,0.8,0.8,0.8l-0.7-0.8c0.5,0.4,1.8,2,2.7,3.3c-0.3-0.3-0.7-0.8-1.1-1.3
						c-0.2-0.3-0.4-0.6-0.7-0.8c-0.3-0.3-0.5-0.6-0.8-0.9c-0.8-0.8,0.4,0.6-0.4-0.1c0.2,0.3,0.6,0.8,0.9,1c0,0,0.1,0.1,0.1,0.2
						c0.1,0.1,0.1,0.2,0.1,0.2c-0.8-0.6-2.3-2.3-3.5-3.2C52.9,35,53,35,53.1,35c-0.8-0.8-1.7-1.5-2.6-2.1c0.4,0.4,0.6,0.5,0.9,0.7
						c0,0.1-0.8-0.6-1.1-0.8c0.8,0.6,0.1,0.2,0.6,0.6c-0.6-0.4-1-0.7-1.7-1.1l0,0l0,0l0,0l0,0c0,0-0.1,0,0.1,0l0,0l0,0l0,0l0,0l0,0
						l0,0c0,0,0,0,0.1,0l0,0l0,0h-0.1l0,0l0,0l0,0l0,0l0,0l0,0h0.1c0.1,0.1,0.3,0.1,0.3,0.1c-0.1-0.1-0.2-0.1-0.3-0.2
						c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.3-0.2-0.6-0.4-1.2-0.6c0.7,0.4,0.6,0.4,0.3,0.3
						c-0.3-0.2-0.9-0.3-1.3-0.4l0.3,0.1c-0.5-0.1-0.9-0.3-1.2-0.5c0.6,0.3,1.2,0.5,1.1,0.3c-0.4-0.2-0.8-0.4-1.3-0.5
						c0,0,0.1,0,0.3,0.1c0.1,0,0.2,0.1,0.2,0.1s-0.1-0.1-0.3-0.1c-0.2-0.1-0.4-0.1-0.7-0.2c-0.5-0.2-1-0.3-1-0.3
						c-0.4,0-0.9,0-1.3,0.1c-0.3-0.1-0.6-0.2-0.8-0.2c-0.6-1-0.9-1-1.1-1h0.3c-0.4-0.1-1.2-0.3-1.9-0.2c-0.7,0-1.3,0-1.3,0
						c-0.7-0.1,4.8-0.3,0-0.4c-1.8,0.1-1.1,0.3-2.1,0.4c0,0,0.1,0-0.1-0.1c-0.8,0.1-0.9,0.2-1.5,0.3c0.5,0,1.1-0.1,1.4-0.2
						c-0.3,0.1-1.6,0.2-2.2,0.4c0.8-0.3-1.5,0.3-0.9,0.1c-0.8,0.1-1.6,0.4-2.3,0.6c-0.7,0.3-1.3,0.6-1.6,0.8
						c-1.1,0.3-1.8,0.8-3.3,1.5c0.3-0.1,0.7-0.4,1-0.5c0.8-0.6-1.6,0.8-0.3,0c-0.3,0.2,0,0.1,0.3-0.1c0.5-0.3,1-0.5,0.9-0.5
						c0,0-0.3,0.1-0.7,0.3c-0.3,0.1-0.7,0.3-0.7,0.3l0.4-0.3c0,0-0.5,0.3-1,0.4c-0.5,0.2-1,0.5-1,0.5c-0.1,0.1,0.1,0,0.3-0.1
						c-0.6,0.3-1.1,0.6-1.7,1c-0.6,0.4-1.1,0.8-1.6,1.1c-0.5,0.4-1.1,0.9-1.5,1.3c-0.3,0.2-0.4,0.4-0.7,0.6
						c-0.2,0.2-0.4,0.4-0.6,0.6c-0.1,0.3-0.9,1.3-1.9,2.5c-0.9,1.2-1.8,2.6-2.2,3.5c0.1-0.4,0.1-0.8-0.1-0.6
						c0.1-0.2,0.3-0.4,0.4-0.8c0.2-0.3,0.4-0.7,0.7-1.1c0.1-0.1,0.8-0.9,0.4-0.3c1.5-2-0.3,0.2,0-0.5c-1.6,2.3-2.2,3.9-2.8,5.5h-0.2
						c0,0-0.1,0.3-0.2,0.6s-0.2,0.6-0.2,0.6c0.4-1,0.6-0.9,0,0.4l0.1-0.2c-0.1,0.2-0.2,0.4-0.3,0.7c-0.1,0.4-0.2,0.6-0.2,0.9
						c-0.1,0.6-0.3,1.3-0.3,1.9l0.1,0.9c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.3c0,0.2-0.1,0.3-0.1,0.4c-0.1,0.2-0.1,0.3-0.2,0.6
						c0.1,1.9,0.4,3.1,0.7,4.4c0,0.3,0.2,1.2,0.3,1.8s0.2,0.9-0.1,0.2c0.3,0.9,0.6,1.1,0.8,1.6c0.1,0.2,0.3,0.5,0.4,0.9
						c0.2,0.4,0.4,0.9,0.6,1.6l-0.4-0.6c0.2,0.3,0.3,0.6,0.5,1c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.3,0.4,0.6,0.6,0.9
						c0.3,0.3,0.5,0.6,0.8,0.9c0.1,0.1,0.3,0.3,0.4,0.4l0.1,0.1l0,0c0.1,0.1-0.1-0.1-0.1-0.1l0,0l0,0l0,0l0,0l0.1,0.1l0.2,0.2
						c0.3,0.3,0.4,0.5,0.7,0.7c0.4,0.4,0.8,0.8,1,1c-0.5-0.3,0.1,0.4,0.9,1.2c0.4,0.4,0.9,0.8,1.3,1.1c0.4,0.3,0.7,0.6,0.8,0.7
						c-0.1-0.1,0.9,0.4,1,0.4c0.2,0.1,0.5,0.4,0.9,0.5c-0.9-0.6,0.8,0.1-1.1-0.8c1.1,0.3,2.3,0.9,4.5,1.6l-0.1,0.1
						c0,0-0.4-0.2-0.9-0.3c-0.3-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.1-0.3-0.1c0.1,0.1-0.1,0-0.4-0.1s-0.6-0.3-0.8-0.3
						c0.8,0.4,1.1,0.6,1.3,0.6c0.1,0.1,0.1,0.1,0,0.1s-0.3,0.1,1.4,0.6c0.4,0,0.1,0,1,0.2c-0.4-0.1-0.5-0.1,0.2,0.1l0.7,0.1
						c0.6,0.3,0.1,0.2-0.4,0.1c-0.6-0.1-1.1-0.3-0.6,0c-0.7-0.1-1.6-0.4-2.3-0.6c-0.7-0.3-1.2-0.4-1-0.2c0.5,0.3,0.9,0.4,1.3,0.5
						c0.3,0.1,0.6,0.1,0.9,0.2s0.6,0.1,0.9,0.2c0.4,0.1,0.8,0.2,1.3,0.3l0.3,0.1c0.8,0.1,1,0,1.4,0c0.1,0.1,0.3,0.1,0.8,0.1
						c1.4-0.1,2.3-0.1,3.6-0.4c-1.7,0.3-2.5,0.1-3.2,0.1c-0.8-0.1-1.5-0.1-3.2-0.4c0.1,0,0.4,0.1,0.7,0.1c0.3,0.1,0.7,0.1,1,0.1
						c0.7,0.1,1.4,0.1,1.4-0.1v0.2c1.1,0,2.3-0.1,3.7-0.3c0,0-0.2,0.1-0.4,0.1c-0.2,0.1-0.4,0.1-0.4,0.1c1.9-0.3,3.1-0.3,4.5-0.9
						c-0.5,0.2-0.9,0.3-1.2,0.4c0.9-0.3,2.1-0.7,3-1.1c0,0,0.4-0.3,0.9-0.6c0.4-0.3,0.9-0.6,0.9-0.6c0,0.1,1.2-0.7,0.7-0.3
						c1.4-0.8,1.8-1.4,2.5-2.1c0.6-0.5,0.4-0.3,0,0.1s-1,0.9-1,1c1.8-1.3,3.4-3.1,4-4.2c-0.3,0.3-0.4,0.5-0.4,0.4
						c0.1,0,0.6-0.8,0.9-1.1l0.1-0.3c0.5-0.7,0.5-0.6,0.1,0.1c0.5-0.7,0.9-1.6,1.3-2.2c-0.1,0.3-0.3,0.6-0.5,0.9
						c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.2,0.3-0.3,0.4c-0.4,0.6-0.8,1.1-0.9,1.4c0.2-0.3,0.4-0.5,0.6-0.8s0.3-0.4,0.4-0.6
						c0.3-0.4,0.5-0.7,0.8-1c0.4-0.6,0.9-1.4,1.5-3.1c0.1,0-0.1,0.6,0.1-0.1c0.4-1.6-0.1-0.4,0-0.9c-0.1,0.6-0.3,1-0.4,1.4
						c-0.1,0.4-0.3,0.6-0.5,0.9l0.1-0.3c0.1,0,0.3-0.8,0.4-0.9l-0.1-0.3c0.1,0.1,0.2-0.2,0.4-0.6c0.1-0.2,0.1-0.4,0.2-0.7
						c0.1-0.3,0.1-0.5,0.1-0.8c-0.3,0.9-0.3,0.7-0.4,0.9c0.1-1.3,0.5-1.4,0.4-3c0.1,0.1,0,1.4,0.1,0.3c0.1-0.9-0.1-0.8-0.1-1.4
						c0.1,0.7,0.3,0.5,0.3,1.3c-0.1,0.9-0.1,1-0.1,1.2s0,0.3-0.2,1.1c0,0,0.1-0.3,0.2-0.7c0.1-0.3,0.1-0.7,0.1-0.7
						c-0.1,0.7-0.1,1-0.1,1.1s0.1,0.1,0.1,0.1c0.3-1.2,0.3-1.7,0.3-2.1c0-0.4-0.1-0.6-0.1-1.4c-0.1-0.8-0.1,1.5-0.1,0.3
						c0-0.8,0-0.8-0.1-0.9c-0.1-0.1-0.1-0.3-0.2-1.1l0.1,0.3c0-0.5-0.1-1-0.3-1.6c-0.1-0.3-0.1-0.1-0.3-0.2s-0.3-0.3-0.6-1.6
						c0.1,0.1,0.1,0.1,0.1,0.1s0.1,0.1,0.3,0.4c0.1,0.2,0.4,0.5,0.6,0.8s0.4,0.6,0.5,0.8c0.1,0.3,0.2,0.4,0.2,0.4
						c-0.3-2.8-1.4-5.9-3.2-8.6c-0.1-0.4,0.3,0.1,0.6,0.6c0.4,0.5,0.8,1.2,0.8,1.1c0,0,0.1,0.1,0.1,0.2s0.1,0.3,0.2,0.4
						c0.1,0.2,0.2,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.1,0.2c-0.1,0-0.4-0.8-0.7-1.3s-0.6-0.9-0.3-0.3c0.7,1.1,1.1,2,1.4,2.9
						c0.3,0.9,0.6,1.8,0.9,3c0-0.4-0.1-1.2-0.4-2.1c-0.1-0.4-0.3-0.9-0.5-1.4c-0.2-0.4-0.3-0.9-0.5-1.3c0.3,0.6,0.5,1.1,0.8,1.7
						c-0.5-1.4-1.2-3-2.2-4.5l-0.3-0.3c0.4,0.5,0.6,0.9,0.8,1.3c0.2,0.3,0.3,0.5,0.3,0.6c-0.8-1.4-1.7-2.3-1.9-2.3l0.1,0.1
						c0.1,0.1,0.1,0.1,0.1,0.1s-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.2-0.2C48.9,42.9,48.7,42.6,48.4,42.3z M28.6,52.1
						c0-0.1-0.1-0.1-0.1-0.2C28.6,51.9,28.6,51.9,28.6,52.1z M47.1,69.8c0.8-0.7,1.5-1.5,2.2-2.3c0.3-0.4,0.6-0.8,1-1.3
						c0.3-0.4,0.6-0.8,0.9-1.3c-1.1,1.4-2.2,2.6-2.5,2.8s0.3-0.4,2-3c0,0,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.1-0.2
						c-0.2,0.3-0.6,0.8-0.9,1.3c-0.4,0.5-0.8,1-1.1,1.5c-0.1,0.2-0.4,0.6-0.6,0.9c-0.3,0.4-0.6,0.8-0.9,1.2
						c-0.3,0.4-0.6,0.7-0.8,0.9c-0.3,0.3-0.4,0.4-0.4,0.4c0.4-0.2,0.7-0.6,1.2-1.1L47.1,69.8z M45.7,70.4C45.2,70.8,45,70.9,45,71
						s-0.1,0.1-0.1,0.2c0.3-0.3,0.6-0.5,0.9-0.8C45.7,70.5,45.7,70.5,45.7,70.4z M45,71.2c0.1-0.1,0.2-0.1,0.3-0.2L45,71.2z
						 M53.3,51.1C53.3,51.2,53.3,51.2,53.3,51.1c-0.1-0.2-0.1-0.5-0.2-0.7C53.1,50.5,53.1,50.7,53.3,51.1z M53.5,53.4l0.1,0.3
						c0-0.3-0.1-0.5-0.1-0.8C53.5,53,53.5,53.1,53.5,53.4z M52.2,60.9c0,0.5-0.6,1.9-0.6,2.1c0,0.1,0.2-0.2,0.3-0.5
						c0.2-0.4,0.4-0.8,0.5-1.3c0,0,0-0.1,0.1-0.3c-0.2,0.4-0.3,0.8-0.3,0.5c0.6-1.1,0.3-1.1,0.6-2.3C52.8,58.7,52.4,60.5,52.2,60.9z
						 M38.1,27.7c0.1,0,0.2,0.1,0.4,0.1c0,0-0.3,0-0.4,0c-0.3,0-0.4,0.1-0.4,0.1c-0.8,0-1.6-0.1-2.3,0.1c-0.8,0.1-1.4,0.1-2.1,0.4
						c-0.7,0.1-1.1,0.1-1.4,0.1c0.3-0.1,0.6-0.1,0.9-0.2c0.5-0.1,0.9-0.3,1.3-0.3c0.4-0.1,0.6-0.1,0.9-0.1c0.4-0.1,0.7-0.1,0.9,0
						c0.3,0,0.4,0.1,0.8,0.1C36.7,27.8,37.2,27.7,38.1,27.7z M20.2,33.8c0.1-0.1,0.2-0.2,0.2-0.2c-2.9,2.5-4.7,5-5.8,6.6
						c0.1-0.1,0.5-0.6,0.9-1s0.8-0.9,1.1-1.3c0,0-0.1,0.2-0.2,0.3c-0.1,0.2-0.2,0.3-0.2,0.3l-0.4,0.3c0,0-0.2,0.3-0.4,0.6
						S15.1,40,15.1,40c0.1-0.1,0.4-0.4,0.6-0.7s0.6-0.6,0.8-0.9c0,0,0.4-0.5,0.8-1s0.9-1.1,1.1-1.3c0,0-0.4,0.3-0.7,0.6
						C17.4,37,17,37.4,17,37.4c0.2-0.2,0.7-0.7,1.3-1.3c0.3-0.3,0.6-0.6,0.8-0.8s0.5-0.4,0.7-0.6c-0.1-0.1-1.3,0.9-1.4,1.2
						c-0.2,0.1,1.2-1.2,0.3-0.5c0.5-0.5,1.3-1.1,1.9-1.6c0.7-0.5,1.4-0.9,1.9-1.3h0.3c1-0.6,0.6-0.6,1.8-1.2c-1.4,0.9,0.3,0-0.8,0.7
						c0.4-0.2,0.1,0.1,1.3-0.6c0.9-0.7,1.3-0.6,2.2-1.1c0.7-0.3-0.6,0.4,0.6-0.1c0.7-0.4,1.1-0.5,1.2-0.6c0.2-0.1,0.1-0.2,0.2-0.3
						c-0.4,0.1-0.6,0.2-0.9,0.3s-0.4,0.2-0.6,0.3c-0.3,0.2-0.6,0.4-1.2,0.6c0,0,0.5-0.3,1.1-0.6c0.3-0.1,0.5-0.3,0.8-0.4
						c0.2-0.1,0.3-0.2,0.3-0.2c-0.4,0.1-0.8,0.3-1,0.4c0.4-0.2,0.8-0.4,1.1-0.6c0.2-0.1,0.4-0.2,0.5-0.3c-1.3,0.4-2.5,1-2.6,1
						l0.1-0.1c-0.5,0.1-1.2,0.6-1.5,0.8c-1.1,0.6-2.1,1.3-2.4,1.4l0.4-0.3c-0.6,0.4-1,0.6-1.4,0.9c-0.2,0.1-0.4,0.3-0.6,0.4
						C20.8,33.3,20.5,33.6,20.2,33.8z M25.4,74.4c-0.4-0.3-0.8-0.6-1.6-1c-0.2-0.1-0.4-0.3-0.7-0.4c-0.3-0.1-0.5-0.3-0.8-0.5
						c-0.6-0.4-1.3-0.8-2.2-1.4l0.3,0.2c-0.9-0.7-1.7-1.3-2.6-2c-0.8-0.8-1.8-1.5-2.5-2.6c0.2,0.3,0.4,0.4,0.5,0.6
						c0.1,0.1,0.3,0.3,0.4,0.3c0.3,0.2,0.5,0.4,1.1,0.8c0,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.2-0.3-0.3c-0.3-0.3-0.5-0.5-0.5-0.5
						c0.2,0.1,0-0.1-0.3-0.4s-0.6-0.8-0.9-1.2l0.7,0.6c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.1,0.3,0.3,0.4,0.5c0.3,0.3,0.6,0.6,0.6,0.6
						c-0.1-0.1,0.2,0.3,0.7,0.8s1.1,1.1,1.7,1.5c-0.1-0.1-2.1-1.9-1.1-1.3c-0.4-0.3-0.6-0.6-0.8-0.8c-0.2-0.3-0.4-0.4-0.8-0.8
						c0.8,0.7,0.3,0.1,0.9,0.6c0,0-0.3-0.3-0.6-0.6c-0.3-0.3-0.6-0.6-0.6-0.6s0.4,0.4,0.8,0.8c0.4,0.3,0.6,0.5,0.1-0.1
						c-0.1-0.1-0.3-0.3-0.5-0.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.4-0.4-0.7-0.9-0.8-0.8v-0.1
						C15.1,65,15,65,14.9,64.8c-0.1-0.1-0.3-0.3-0.6-1c-0.1,0,0.1,0.3,0.4,0.8c0.3,0.4,0.3,0.4,1,1.3c0.2,0.4-0.8-1-0.4-0.3
						c0.1,0.2,0.5,0.7,0.9,1.1c0.1,0.1,0.2,0.2,0.3,0.3c0-0.1,0.1,0,0.1,0c0.1,0.1,0.1,0.1-0.1,0c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1
						l0,0l0,0l0,0l0,0l0,0c0,0,0,0.1,0,0l0,0l0,0v-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.3-0.4-0.6-0.4-0.6c-0.1,0.1-0.8-0.8-0.8-0.7
						c-0.3-0.3-0.4-0.7-0.6-1.1c-0.2-0.4-0.4-0.7-0.6-1.1c-0.3-0.3-0.1,0.3-0.6-0.9c-0.1-0.1-0.1-0.3-0.2-0.5
						c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.4-0.3-1-0.5-1.5c-0.3-1.1-0.6-2.1-0.7-2.8L11,55.6c-0.1,0.1,0.4,2,0.1,1.4l-0.3-1.6l0.1,0.1
						c-0.1-0.6-0.1-1.1-0.3-1.6c0.1,0.5,0.2,1.7,0.3,1.4c-0.1-0.8,0-0.9-0.1-1.6c0,0.2,0.1,0.3,0.1,0.3c-0.1-0.8-0.1-1.2-0.1-1.4
						c-0.1-0.3-0.1-0.3-0.1-0.5c-0.2-1.6-0.2,1.6-0.3,1.4c0,0,0-0.1,0-0.2s-0.1-0.3-0.1-0.6c0-0.4,0-1.1,0-1.6
						c0-0.6,0.1-1.2,0.1-1.6c0.1-0.4,0.1-0.8,0.1-0.8c0,0.4-0.1,0.8,0.1,0.7c0.1-0.6,0.1-0.6,0.2-0.6c0.1-0.1,0-0.2,0.2-0.9
						c-0.1,0.1-0.1-0.5,0.1-1.1c0.1-0.3,0.2-0.7,0.3-1s0.2-0.7,0.3-0.9c0.3-0.6,0.3-0.7-0.1,0.4c0.1-0.1,0.7-1.6,1-2.1
						c0.1-0.3,0.4-0.8,0.5-1.1c-0.3,0.4-0.3,0.2,0.3-0.8v-0.1c-0.4,0.7-1,1.6-1.4,2.5c-0.2,0.4-0.4,0.9-0.6,1.3
						c-0.1,0.4-0.3,0.8-0.3,1l0.1-0.3c-0.2,0.4-0.3,0.9-0.4,1.4c-0.1,0.5-0.2,1-0.3,1.4c-0.1,0.4-0.1,0.9-0.1,1.3
						c-0.1,0.4-0.1,0.7-0.1,0.9c-0.1-0.1,0,0.4-0.1,0.4l0.1,1.4L10,52.3c-0.1,0,0.2,2.5,0.2,2.5v-0.1c0.1,0.6,0.3,1.1,0.4,1.5
						c0.1,0.4,0.3,0.8,0.3,1.2c0.1,0,0.1-0.1,0.2,0.3c0.1,0.4,0.2,0.6,0.3,0.9l0,0l0,0c0.1,0.6,0.1,1,0.4,1.7c0,0-0.1-0.1-0.1-0.3
						c-0.1-0.2-0.2-0.4-0.3-0.8c-0.2-0.6-0.4-1.1-0.4-1.1c-0.1-0.2-0.2,0.1,0.1,1.1c-0.1-0.4-0.2-0.6-0.1,0c0,0,0.1,0.4,0.3,0.7
						c0.1,0.3,0.3,0.5,0.2,0.2c0.2,0.5,0.4,1.1,0.6,1.8c0.3,0.6,0.6,1.3,0.9,2c0.4,0.7,0.8,1.4,1.3,2.1c0.3,0.3,0.5,0.6,0.8,0.9
						l0.4,0.5l0.4,0.4c-0.2-0.2-0.4-0.3-0.4-0.4c-0.1-0.1-0.1-0.1-0.1-0.1s0,0.1,0.1,0.2s0.1,0.2,0.2,0.3c0.3,0.3,0.6,0.7-0.4-0.3
						c1.2,1.5,1.2,1.1,2.5,2.4c0.1,0.1-0.1-0.1-0.4-0.3c-0.3-0.3-0.6-0.5-0.8-0.6c1.1,1.1,2.1,1.9,3.3,2.6c1.1,0.7,2.3,1.3,3.6,1.9
						l0.1,0.2C24.2,74,24.8,74.2,25.4,74.4z M31.2,75.5c0.6,0,1.1,0.1,1.8,0c-0.1,0-0.1,0-0.2,0c0,0,0.4-0.1,0.9-0.1
						c0.4-0.1,0.9-0.1,0.9-0.1c-0.5,0.1-0.9,0-1.1-0.1c-0.1-0.1,0.1-0.1,0.8-0.2c-0.1,0.1,0.3,0.1-0.2,0.1c1,0.1,3.1-0.3,2.6-0.4
						c-0.9,0.3-1.1,0.2-1.1,0.1c-0.1-0.1-0.1-0.1-0.8,0.1c0.8-0.2,1.6-0.3,2.3-0.5c-0.8,0.3,0.3,0.2,1.4-0.1c0.5-0.1,1.8-0.6,2.6-1
						c-0.3,0,0.3-0.3-0.9,0.2c0.3-0.1,0.5-0.3,0.9-0.4c0.3-0.2,0.7-0.3,1.1-0.5c-0.3,0.2-0.8,0.4-0.8,0.4c0.3-0.1,0.6-0.2,0.8-0.3
						c0.3-0.1,0.5-0.3,0.6-0.3c0.3-0.2,0.5-0.3,0.8-0.4c-0.1,0.1-0.1,0.1-0.2,0.1l0.8-0.6c-1.1,0.8-0.5,0.3-0.6,0.3
						c0.1,0.1,0.7-0.4,1.1-0.7c0,0-0.2,0.1-0.4,0.3c-0.2,0.1-0.4,0.2-0.4,0.2c0.4-0.3,1.4-0.9,1.1-0.8c-0.8,0.7-1.4,1.1-2,1.4
						c-0.3,0.2-0.6,0.3-0.9,0.5c-0.3,0.2-0.7,0.3-1.1,0.6l0.7-0.3c-0.9,0.5-1.3,0.6-1.6,0.6c-0.2,0.1-0.4,0.1-0.6,0.1
						c-0.3,0.1-0.6,0.2-1.2,0.3c-0.4,0.2-1.3,0.4-2,0.5c-0.4,0.1-0.7,0.1-0.9,0.2c-0.3,0.1-0.4,0.1-0.4,0.1c0.1-0.1-0.3-0.1-0.7,0
						c-0.5,0-1.1,0-1.5,0.1C32.3,75,31.9,75,31.5,75s-0.8,0-1.1,0c-0.7-0.1-1.1-0.1-0.8,0c0.4,0.1,0.8,0.1,1.2,0.1
						c0.4,0.1,0.8,0.1,1.1,0.1c0,0-0.4,0-0.7,0c-0.4,0-0.7-0.1-0.7-0.1l0.9,0.2C31,75.2,31,75.4,31.2,75.5z M54.8,57.1L54.8,57.1
						L54.8,57.1L54.8,57.1z"
              />
              <path
                className={color0}
                d="M56.6,36.3c0.3,0.3,0.9,1.1,0.9,0.8C57.5,37.2,56.3,35.8,56.6,36.3z"
              />
              <path
                className={color0}
                d="M57.3,37.2c0.1,0.1,0.1,0,0.4,0.3c0,0-0.2-0.3-0.4-0.5c-0.2-0.3-0.4-0.4-0.4-0.4s0.1,0.1,0.3,0.3
						C57.2,37.1,57.3,37.2,57.3,37.2z"
              />
              <path
                className={color0}
                d="M57.6,38.7c0.4,0.4,0.8,0.8,1.3,1.6c0,0.4-1-1.3-1.4-1.5c1.1,1.4,1.6,1.8,2.4,3c-0.1-0.4-0.6-1.1-1.1-1.9
						C58.2,39.2,57.7,38.6,57.6,38.7z"
              />
              <path
                className={color0}
                d="M56.8,38.2c0,0,0.2,0.2,0.3,0.4c0.2,0.2,0.3,0.4,0.3,0.4l-0.1-0.1c0,0-0.2-0.2-0.3-0.4
						C56.9,38.4,56.8,38.2,56.8,38.2L56.8,38.2z"
              />
              <path
                className={color0}
                d="M38.5,26.3c-0.1-0.1-0.8-0.1-1.1,0c0.1,0,0.3,0,0.5,0C38.1,26.3,38.3,26.3,38.5,26.3z"
              />
              <path
                className={color0}
                d="M45.2,52.1c0,0,0-0.1-0.1-0.4c-0.1-0.3-0.1-0.6-0.2-1c-0.1-0.4-0.3-0.9-0.4-1.4c-0.1-0.3-0.3-0.5-0.3-0.8
						c-0.1-0.3-0.3-0.5-0.4-0.8c-0.1-0.3-0.3-0.5-0.4-0.7c-0.1-0.3-0.4-0.4-0.5-0.6c-0.2-0.2-0.4-0.3-0.6-0.4
						c-0.2-0.2-0.4-0.3-0.6-0.5C41.4,45.3,41.1,45,41,45c-0.2-0.1-0.3-0.2-0.3-0.2c0.3,0.2,0.4,0.3,0.8,0.5c0.3,0.3,0.5,0.4,0.8,0.7
						s0.5,0.4,0.8,0.6c0.1,0.1,0.3,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.4C44.3,48.6,45,50.2,45.2,52.1z"
              />
              <path
                className={color0}
                d="M15.1,37.9c0.6-0.9,1.1-1.4,1.7-2.1c0.6-0.6,1.1-1.2,1.9-1.9c0,0.1-0.1,0.1-0.1,0.1
						c-0.1,0.1-0.1,0.1-0.3,0.2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.3,0.2-0.4,0.4-0.6,0.6c-0.4,0.4-0.7,0.8-1,1.1
						c-0.6,0.7-1.1,1.4-1.9,2.3c0,0,0.2-0.2,0.4-0.3C14.9,38,15.1,37.9,15.1,37.9z"
              />
              <path
                className={color0}
                d="M15.1,36.9c0.3-0.2-0.9,1.4-0.6,1.3c0.9-1.2,0.3-0.6,1.2-1.6L15.1,36.9z"
              />
              <polygon className={color0} points="24,32.6 23.5,33 24,32.7 					" />
              <path
                className={color0}
                d="M10.2,45.6l0.1-0.3c0,0-0.1,0.3-0.2,0.5C10,46,9.9,46.4,9.9,46.4s0-0.2,0.1-0.4S10.2,45.6,10.2,45.6z"
              />
              <path className={color0} d="M10.3,45.1l-0.1,0.3L10.3,45.1z" />
              <polygon
                className={color0}
                points="23.5,33.3 24.3,32.8 24.7,32.5 					"
              />
              <path
                className={color0}
                d="M16.8,37.9c0,0,0.1-0.2,0.3-0.3c0.1-0.2,0.3-0.3,0.3-0.3C17.2,37.4,16.4,38.2,16.8,37.9z"
              />
              <path
                className={color0}
                d="M9.1,52.4l0.1,1.1v-0.1c0,0,0-0.1,0-0.2s0-0.3,0-0.4c0-0.3,0-0.6,0-0.6L9.1,52.4z"
              />
              <path
                className={color0}
                d="M13.5,43.4c0.3-0.4-0.7,1.5-0.1,0.5C13.9,42.9,13.6,43.3,13.5,43.4z"
              />
              <path
                className={color0}
                d="M10.8,55.1c0,0.1,0,0.3,0.1,0.4C10.8,55.3,10.8,55.1,10.8,55.1z"
              />
              <path
                className={color0}
                d="M10.8,55.1c-0.1-0.4,0-0.9-0.1-1.2C10.7,54.4,10.7,54.6,10.8,55.1z"
              />
              <path
                className={color0}
                d="M11.9,60l-0.1-0.4c0.2,0.4,0.1,0.2-0.1-0.1c-0.1-0.4-0.1-0.4-0.2-0.4c-0.1-0.1-0.1-0.2-0.1-0.3
						c0.1,0.1,0.1,0.3,0.1,0.3C11.7,59.8,11.9,60.2,11.9,60z"
              />
              <path
                className={color0}
                d="M23.7,75.2c-1.4-0.7-1.8-0.9-1.7-0.9c-0.7-0.4-0.9-0.5-1.2-0.6s-0.4-0.1-0.8-0.4c0.8,0.4,1.2,0.8,1.7,1
						C22.2,74.6,22.7,74.8,23.7,75.2z"
              />
              <path
                className={color0}
                d="M20,73.3c-0.2-0.1-0.3-0.2-0.5-0.3C19.7,73.1,19.8,73.2,20,73.3z"
              />
              <polygon
                className={color0}
                points="11.7,54.1 11.9,55 11.8,54.7 					"
              />
              <path
                className={color0}
                d="M25.9,76.1c-0.3-0.1-0.8-0.3-0.9-0.4C24.9,75.8,26.2,76.2,25.9,76.1z"
              />
              <path
                className={color0}
                d="M21.2,72c-0.1-0.1-0.9-0.6-1.3-0.9C20.4,71.5,20.7,71.9,21.2,72z"
              />
              <path
                className={color0}
                d="M30.5,76.7c-0.3,0-0.8-0.1-0.6-0.2C29.6,76.4,28.9,76.6,30.5,76.7z"
              />
              <path
                className={color0}
                d="M23.5,73.4l-0.2-0.1c0,0-0.3-0.1-0.5-0.2c-0.3-0.1-0.4-0.2-0.4-0.2s0.3,0.1,0.6,0.3
						C23.3,73.3,23.5,73.4,23.5,73.4z"
              />
              <path
                className={color0}
                d="M21.1,70.5c-0.9-0.6,1.3,1.2-0.3,0.2c0.5,0.4,0.8,0.6,0.9,0.6c0.1,0.1,0.1,0,0.1-0.1
						c-0.1-0.1-0.3-0.3,0.6,0.2C21.3,70.9,21,70.5,21.1,70.5z"
              />
              <path
                className={color0}
                d="M22.2,71.5c0.1,0.1,0.3,0.2,0.4,0.3C22.5,71.7,22.3,71.5,22.2,71.5z"
              />
              <path
                className={color0}
                d="M37.3,74.5c-0.2,0.1-0.3,0.1-0.4,0.1C37.1,74.6,37.2,74.6,37.3,74.5z"
              />
              <path
                className={color0}
                d="M27.6,73.7c0,0-0.4-0.1-0.8-0.1c-0.4-0.1-0.8-0.2-0.8-0.2c0.3,0.1,0.6,0.2,1.1,0.3
						c0.4,0.1,0.8,0.2,1.3,0.3c0.4,0.1,0.8,0.1,1,0.2c0.3,0,0.4,0,0.4-0.1c-0.9-0.2-1.9-0.4-2.4-0.4L27.6,73.7z"
              />
              <path
                className={color0}
                d="M47,71.8l-0.6,0.6l0.8-0.6C47,71.9,47.4,71.5,47,71.8z"
              />
              <path
                className={color0}
                d="M47.4,71.6L47.4,71.6C47.3,71.7,47.4,71.7,47.4,71.6z"
              />
              <path
                className={color0}
                d="M34.2,75.1c-0.6,0.1-1,0.1-1.5,0.1C32.8,75.2,34.2,75.2,34.2,75.1z"
              />
              <path
                className={color0}
                d="M50.8,67.5c0,0-0.2,0.3-0.4,0.6c-0.2,0.3-0.4,0.5-0.4,0.5s0.3-0.3,0.4-0.6C50.6,67.7,50.8,67.5,50.8,67.5
						L50.8,67.5z"
              />
              <path
                className={color0}
                d="M54.1,62.3l0.1-0.2l0.3-1.1l-0.1,0.2c0,0-0.1,0.3-0.2,0.6C54.2,62,54.1,62.3,54.1,62.3z"
              />
              <path
                className={color0}
                d="M47.5,67.2c-0.6,0.6-0.9,1.1-0.7,1c0,0,0.3-0.3,0.5-0.6c0.3-0.3,0.5-0.6,0.5-0.6c-0.4,0.2-0.3,0.3-0.9,0.9
						c0,0,0.1-0.2,0.3-0.4C47.4,67.4,47.5,67.2,47.5,67.2z"
              />
              <path
                className={color0}
                d="M27.8,37.9c0.1-0.1,0.3-0.1,0.4-0.3c0.2-0.1,0.5-0.2,0.8-0.3c0.5-0.2,1-0.3,1.1-0.3
						c-0.3,0.1-1.2,0.5-0.6,0.3c0,0,0.3-0.2,0.7-0.3c0.3-0.2,0.7-0.3,0.7-0.3c0.9-0.2,0.6-0.1-0.1,0.2c-0.3,0.1-0.8,0.3-1.2,0.5
						c-0.4,0.2-0.8,0.3-1,0.4c0.9-0.4,1.6-0.6,2.2-0.8c0.3-0.1,0.6-0.2,1-0.3c0.4-0.1,0.8-0.3,1.3-0.4c-0.6,0.1-1,0.2-1.3,0.3
						c-0.1-0.1,1.8-0.6,3.3-0.8c-0.9,0.1-0.4-0.1,0.3-0.2c0.1,0,0.2-0.1,0.3-0.1s0.1,0,0.3-0.1h-0.2c0,0,0,0-0.2-0.1
						c-1.3,0.2-2.1,0.3-2.9,0.4c0.4,0-0.3,0.2-1.1,0.4c-0.4,0.1-0.9,0.3-1.3,0.4s-0.8,0.3-1,0.4l-1.1,0.4l-0.5,0.3
						c-0.1,0,0-0.1,0.6-0.3c-0.1,0.1-0.3,0.1-0.5,0.3c-0.2,0.1-0.4,0.2-0.7,0.3c-0.4,0.3-0.8,0.4-0.8,0.4s-0.3,0.1-0.6,0.3
						c-0.4,0.2-0.8,0.5-1.3,0.8c-0.4,0.3-0.8,0.7-1.1,0.9c-0.2,0.1-0.3,0.3-0.4,0.3c-0.1,0.1-0.1,0.1-0.1,0.1
						c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.1,0.1-0.1,0.1l-0.1,0.1c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.2-0.4,0.4-0.6,0.7
						s-0.4,0.6-0.7,0.9c-0.5,0.7-1,1.6-1.4,2.6s-0.8,2.1-1.1,3.3C18,49.8,18,50,18,50.4c-0.1,0.3-0.1,0.6-0.1,0.9
						c0,0.3-0.1,0.6-0.1,0.9c0,0.3,0,0.5,0,0.8v0.2v0.1l0,0v0.1v0.1c0,0.1,0,0.3,0,0.4c0,0.3,0,0.5,0.1,0.8c0.1,0.5,0.1,1,0.3,1.4
						c0.2,0.9,0.4,1.6,0.6,2.1s0.3,0.8,0.3,0.8c-0.2-0.4-0.4-0.9-0.4-1.3c-0.1-0.4-0.3-0.9-0.3-1.3c0.3,0.7,0.1,0.3,0.1-0.2
						c-0.1-0.5-0.2-1.1-0.1-0.9c0.1,0.2,0.1,0.3,0.1,0.5s0.1,0.4,0.1,0.6c0.1,0.4,0.2,0.8,0.3,1s-0.1-0.6,0.1-0.6
						c-0.3-1.1-0.4-1.8-0.5-2.5c0-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.2-0.1-0.3v-0.1v-0.1v-0.1c0-0.2,0-0.4,0-0.6s0-0.4,0.1-0.8
						c0.1,0.1,0.1,0.8,0.2-0.7v0.4c0-0.6,0.1-1,0.2-1.3s0.1-0.5,0.2-0.6c0.1-0.3,0.1-0.6,0.3-1.3l0.1,0.1c0.1-0.5,0.4-1.3,0.7-2
						c0.3-0.8,0.7-1.4,1-1.9v0.1c0.2-0.4,0.4-0.6,0.6-0.9c0.2-0.3,0.3-0.4,0.4-0.6c0.3-0.3,0.4-0.5,0.6-0.7s0.3-0.4,0.7-0.7
						c0.1-0.1,0.3-0.3,0.6-0.6c0.3-0.2,0.6-0.4,0.9-0.8c0,0-0.1,0.1-0.2,0.2s-0.3,0.3-0.4,0.4c-0.1,0.1-0.3,0.3-0.4,0.4
						c-0.1,0.1-0.2,0.2-0.2,0.2c0.9-0.8,1.1-1.1,1.9-1.6c-0.1,0.1,0.2,0-0.5,0.5c0,0,0.9-0.7,1.8-1.3c0.3-0.1,0.5-0.3,0.7-0.4
						c0.1-0.1,0.1-0.1,0.2-0.1h0.1l0.1-0.1l0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.1C28.7,38.1,29,38,29,38C29.1,37.5,27.9,37.9,27.8,37.9z"
              />
              <path
                className={color0}
                d="M33,36.4c0.3-0.1,0.5-0.1,0.8-0.2C33.5,36.3,33.2,36.4,33,36.4z"
              />
              <path
                className={color0}
                d="M19.5,60c0,0-0.7-1.2-0.6-1.2C19.1,59.2,19.3,59.5,19.5,60z"
              />
              <path
                className={color0}
                d="M39.3,35.8c-0.8-0.2,0.6,0,0.8,0c0,0-0.2,0-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.3,0-0.5-0.1
						c-0.3,0-0.7-0.1-0.9-0.1c-0.2,0-0.4,0-0.4,0c0,0.1-0.3,0.1-0.2,0.1c0,0,0.1,0,0.4,0c0.1,0,0.1,0,0.2,0H38h0.1H38
						c0.1,0,0.3,0,0.4,0c0.3,0,0.5,0.1,0.7,0.1C39.1,35.7,39.3,35.8,39.3,35.8z"
              />
              <path
                className={color0}
                d="M36.4,35.4c-0.1,0.1-0.4,0.1-0.7,0.2c0,0,0.4-0.1,0.8-0.1c0.4,0,0.8,0,0.8,0
						C37.2,35.5,37.1,35.4,36.4,35.4z"
              />
              <path
                className={color0}
                d="M36.8,38h-0.4l-0.6,0.1C35.7,38.1,36.8,37.9,36.8,38z"
              />
              <path
                className={color0}
                d="M30.1,39.2c0.5-0.1,1.4-0.5,1.8-0.6C30.7,39,31.2,38.8,30.1,39.2z"
              />
              <polygon
                className={color0}
                points="31.7,39.5 30.6,39.9 30.7,39.9 					"
              />
              <path
                className={color0}
                d="M27.2,65.6c0.2,0.1,0.4,0.1,0.6,0.2C27.5,65.7,27.3,65.7,27.2,65.6z"
              />
              <path
                className={color0}
                d="M19.3,58.7c0.6,1.4,0.4,0.9,0,0.3c-0.2-0.3-0.4-0.8-0.5-1c-0.1-0.3-0.2-0.4-0.2-0.1c0,0,0.2,0.4,0.4,0.8
						c0.2,0.4,0.4,0.8,0.4,0.8l-0.1-0.1c0,0,0.1,0.1,0.2,0.3c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.4,0.6,0.9,0.6,0.9
						c-0.2-0.4-0.4-0.7-0.3-0.7c0.2,0.3,0.4,0.6,0.5,0.7c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.2,0.2,0.3,0.2,0.3s0,0.1,0.1,0.2
						c0.1,0.1,0.1,0.2,0.3,0.3c0.1,0.1,0.3,0.3,0.4,0.4c0,0,0.1,0.1,0.3,0.3s0.5,0.4,0.8,0.6c0.3,0.3,0.6,0.4,0.8,0.6
						c0.3,0.2,0.4,0.3,0.4,0.3L24,64.3c0.2,0.1,0.3,0.2,0.4,0.3c0,0,0.1,0.1,0.4,0.2s0.6,0.3,1,0.6c0.9,0.4,2,0.9,3.2,1.2
						c0.6,0.2,1.3,0.3,1.8,0.4c0.6,0.1,1.1,0.1,1.6,0.1c0.5,0,0.9,0,1.2-0.1c0.3,0,0.4-0.1,0.4-0.1c1.9-0.3-1.1,0,0.3-0.3
						c-1.1,0.2-2.4,0.1-3.5,0c-0.5-0.1-1-0.2-1.3-0.2c-0.3-0.1-0.6-0.1-0.6-0.1c0.1,0,0.2,0-0.2-0.1c0,0-0.1,0-0.2,0
						s-0.3-0.1-0.4-0.1C27.8,66,27.5,66,27.5,66s0.3,0.1,0.6,0.3c0.1,0.1,0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.1,0.2,0.1
						c-0.6-0.1-1.8-0.5-3-1.1c-0.5-0.3-1.1-0.6-1.4-0.9s-0.7-0.5-0.8-0.6c0.9,0.6,1.2,0.8,1.6,0.9s0.8,0.4,2.2,1.1
						c-0.1-0.1-0.6-0.4,0.1-0.1c-1.7-0.7-3.1-1.6-4.5-2.7c-0.3-0.3-0.6-0.6-0.9-0.9l-0.3-0.3l-0.4-0.3l-0.4-0.4
						c-0.1-0.1-0.3-0.4-0.4-0.6c-0.1-0.2-0.3-0.4-0.4-0.6C19.8,59.5,19.5,59.2,19.3,58.7z"
              />
              <path
                className={color0}
                d="M45.2,52.7L45.2,52.7C45.2,52.7,45.2,52.6,45.2,52.7z"
              />
              <path
                className={color0}
                d="M45.2,53.9c0,0,0,0.1,0,0.3s0,0.4,0,0.6c0,0.4-0.1,0.9-0.1,0.9s0-0.2,0.1-0.4c0-0.3,0.1-0.6,0.1-0.9
						c0-0.4,0-0.7,0.1-0.9c0-0.3,0-0.4,0-0.4l-0.2-0.2c0,0.1,0.1,0.4,0.1,0.8c0,0.3,0,0.7-0.1,0.9C45.2,54.6,45.2,53.9,45.2,53.9z"
              />
              <path
                className={color0}
                d="M44.7,57.9c0.2-0.7,0.2-1.1,0.2-1.1c-0.1-0.1-0.1,0.1-0.3,0.5c0.3-1.3,0.4-0.8,0.4-1.7
						c-0.1,0-0.2,0.5-0.3,0.9s-0.2,0.9-0.3,1.1L44.7,57.9z"
              />
              <path
                className={color0}
                d="M37.4,65.9c0,0,0.4-0.2,0.4-0.3L36.9,66L37.4,65.9z"
              />
              <path
                className={color0}
                d="M37.7,65.7l0.9-0.5c-0.1,0-0.2,0-0.3,0.1L37.7,65.7z"
              />
              <path
                className={color0}
                d="M40.7,63.6c0.1-0.1,0.1-0.1,0.2-0.2l0.1-0.1l0.1-0.1l0,0v-0.1l0.1-0.1c0.1-0.2,0.3-0.4,0.5-0.6
						c0,0-0.3,0.3-0.5,0.6l-0.1,0.1L41,63.2l0,0l0,0c-0.1,0.1-0.1,0.1-0.1,0.1C40.8,63.6,40.7,63.6,40.7,63.6z"
              />
              <path
                className={color0}
                d="M39.9,64.3L39.9,64.3c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.3c0.2-0.2,0.4-0.3,0.4-0.3
						c-0.1,0.1-0.3,0.3-0.4,0.4C40.2,64.1,40.1,64.2,39.9,64.3z"
              />
              <path
                className={color0}
                d="M38.6,65.3c0.1,0,0.2,0,0.4-0.1c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.5-0.4,0.8-0.6H40c0,0-0.3,0.3-0.7,0.5
						c-0.2,0.1-0.3,0.3-0.4,0.3C38.7,65.2,38.6,65.3,38.6,65.3z"
              />
              <path
                className={color0}
                d="M24.8,42.9l-0.6,0.7l0.1-0.1l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.3c0.2-0.2,0.3-0.4,0.3-0.4L24.8,42.9z"
              />
              <path
                className={color0}
                d="M21.6,47.3c-0.1,0.3-0.1,0.4-0.2,0.6C21.5,47.7,21.6,47.5,21.6,47.3z"
              />
              <path
                className={color0}
                d="M21.2,51.4c-0.1,0.5-0.1,0.1-0.1-0.1s0-0.4-0.1,0.6c-0.1,0.2-0.2-0.8,0.1-1.7c0.1,0.1-0.1,0.9,0.1,0.4
						c0-0.6,0.2-0.9,0-0.7c0.1-0.5,0.1-0.8,0.2-1.1s0.1-0.5,0.2-0.8c-0.3,0.6-0.6,1.6-0.8,2.6c-0.1,0.5-0.1,1.1-0.2,1.6
						c0,0.3,0,0.6,0,0.8v0.2l0,0l0,0l0,0c0-0.1,0,0,0-0.1l0,0l0,0v0.1v0.1c0,0.1,0,0.2,0.1,0.3c0.2,0.9,0.4,0.3,0.6,0.3
						c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0-0.5s0-0.3,0-0.5c0-0.1,0-0.3,0-0.4C21.2,51.6,21.2,51.4,21.2,51.4z"
              />
              <path
                className={color0}
                d="M21.7,49.8c0.1-0.5,0.2-0.6,0.3-0.9C21.8,48.9,21.8,49.4,21.7,49.8z"
              />
              <path
                className={color0}
                d="M22.9,61c0.1,0.1,0.8,0.7,0.8,0.5c0,0-0.1-0.1-0.2-0.1s-0.3-0.3-0.4-0.3c-0.1-0.1-0.3-0.3-0.4-0.4
						c-0.1-0.1-0.1-0.2-0.1-0.2C22.7,60.8,22.9,60.9,22.9,61z"
              />
              <path
                className={color0}
                d="M22.1,60l0.4,0.5C22.4,60.4,22.3,60.2,22.1,60z"
              />
              <path
                className={color0}
                d="M22.9,61.1C22.9,61.1,22.9,61,22.9,61.1C22.9,61,22.9,61,22.9,61.1z"
              />
              <path
                className={color0}
                d="M43.7,60.2c0,0,0.1-0.1,0.2-0.3c0.1-0.2,0.2-0.5,0.3-0.7C44,59.5,43.8,59.9,43.7,60.2z"
              />
              <path
                className={color0}
                d="M29.1,46.9c0,0-0.1,0.1-0.3,0.3c-0.1,0.1-0.3,0.3-0.3,0.3C28.8,47.2,28.9,47.2,29.1,46.9z"
              />
              <path
                className={color0}
                d="M31,55.5c0,0,0.1,0,0.1,0.1C31,55.5,31,55.5,31,55.5z"
              />
              <path
                className={color0}
                d="M31,55.5c0.2,0.1,0.5,0.3,0.9,0.4c0.4,0.1,0.8,0.3,1,0.3h-0.1c0,0,0.2,0,0.4,0.1c0.2,0.1,0.4,0.1,0.4,0.1
						c-0.2,0-0.4-0.1-0.7-0.1c-0.3-0.1-0.5-0.1-0.8-0.2c-0.3-0.1-0.5-0.2-0.7-0.3C31.3,55.6,31.1,55.5,31,55.5z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g>
      <path
        className={color0}
        d="M76.9,54.8v21.6h-3.6V30.1h16.6c9.1,0,14,4.9,14,12.1c0,5.9-3.5,9.7-8.7,10.9c4.7,1.1,7.9,4.2,7.9,12.1V67
		c0,3.3-0.3,7.6,0.6,9.4h-3.6c-0.9-2-0.7-5.7-0.7-9.7v-1.2c0-7.6-2.2-10.8-11.1-10.8L76.9,54.8L76.9,54.8z M76.9,51.5h11.2
		c8.1,0,11.9-3,11.9-9.1c0-5.7-3.7-9-10.9-9H76.9V51.5z"
      />
      <path
        className={color0}
        d="M115.1,64c1.4,6.1,5.5,9.8,13.1,9.8c8.4,0,11.7-4.2,11.7-9.2c0-5.3-2.3-8.4-12.5-10.9c-9.8-2.4-14-5.6-14-12.3
		c0-6.1,4.4-11.8,14.3-11.8c10.2,0,14.5,6.2,15.1,11.8H139c-1-5-4.3-8.5-11.5-8.5c-6.6,0-10.3,3.1-10.3,8.3c0,5.1,3,7.1,11.5,9.2
		c13,3.3,14.9,8.4,14.9,14c0,6.8-5,12.7-15.7,12.7c-11.5,0-15.8-6.7-16.6-12.9L115.1,64L115.1,64z"
      />
      <path
        className={color0}
        d="M165.6,76.4l-17.1-46.3h3.9l9,24.2c2.6,7,5.5,14.7,6.5,18.6h0.2c1-3.4,4.1-11.5,6.7-18.4l9.3-24.5h3.8
		l-17.8,46.3L165.6,76.4L165.6,76.4z"
      />
      <path
        className={color0}
        d="M193.4,27.7h3.3v6.4h-3.3V27.7z M193.4,43.4h3.3v33.1h-3.3V43.4z"
      />
      <path
        className={color0}
        d="M207.2,67.3c1.1,4.3,4.1,6.9,9.4,6.9c5.9,0,8.3-2.7,8.3-6.4c0-3.8-1.6-5.6-9-7.4c-8.5-2.1-10.7-4.7-10.7-9
		c0-4.6,3.4-8.8,10.9-8.8s11,4.6,11.5,9.2h-3.3c-0.6-2.8-2.8-6.2-8.4-6.2c-5.8,0-7.4,2.8-7.4,5.5c0,3,1.6,4.6,8.1,6.1
		c9.3,2.2,11.6,5.3,11.6,10.3c0,6-4.9,9.7-11.8,9.7c-7.5,0-11.8-4-12.7-9.9L207.2,67.3L207.2,67.3L207.2,67.3z"
      />
      <path
        className={color0}
        d="M236.5,27.7h3.3v6.4h-3.3V27.7z M236.5,43.4h3.3v33.1h-3.3V43.4z"
      />
      <path
        className={color0}
        d="M277.5,59.9c0,9.2-5.1,17.4-14.9,17.4c-9,0-14.5-7.3-14.5-17.3c0-9.5,5.3-17.3,14.8-17.3
		C271.6,42.5,277.5,49.4,277.5,59.9z M251.5,59.9c0,7.8,4.2,14.2,11.2,14.2c7.2,0,11.2-5.9,11.2-14.2c0-7.8-4-14.2-11.3-14.2
		C255.1,45.6,251.5,51.9,251.5,59.9z"
      />
      <path
        className={color0}
        d="M285.7,51.7c0-2.9,0-5.7-0.1-8.4h3.3c0.2,1,0.2,5.3,0.2,6.3c1.5-3.4,4.1-7.1,10.9-7.1
		c5.9,0,11.1,3.3,11.1,12.4v21.5h-3.3V55.6c0-5.9-2.4-9.7-8.2-9.7c-7.8,0-10.6,6.5-10.6,13.8v16.9h-3.3V51.7L285.7,51.7z"
      />
    </g>
  </svg>
);

export default Logo;
