/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from 'theme-ui';
import { Global } from '@emotion/core';
import { Navbar, Nav } from 'react-bootstrap';
import Logo from '../images/logo';
import { Link } from 'gatsby';
import Footer from './footer';
import { logout, isAuthenticated, getProfile } from '../utils/auth';
import CloseIcon from '../images/close_icon';
import MenuIcon from '../images/menu_icon';
import '../stylesheets/App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const Layout = ({ children }) => {
  const [onToggle, setToggle] = useState(false);
  const handleClick = () => setToggle(!onToggle);
  const user = getProfile();

  return (
    <div className="content">
      <Global
        styles={(theme) => ({
          '*': {
            boxSizing: 'border-box',
          },
          body: {
            background: 'none',
          },
        })}
      />
      <header>
        <div className="spacer" />
        <div className="nav_wrap">
          <Navbar expand="sm" fixed="top">
            <Navbar.Brand>
              <Link className="navbar-brand" to="/">
                <Logo baseLayer="logo" color0="color0" />
              </Link>
            </Navbar.Brand>
            {/* <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={handleClick}
            >
              {onToggle ? (
                <CloseIcon baseLayer="icon" />
              ) : (
                <MenuIcon baseLayer="icon" />
              )}
            </Navbar.Toggle> */}
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                {/* {!isAuthenticated() ? (
                  <div className="nav-item">
                    <Link to="/account" className="nav-link">
                      SIGN UP | LOGIN
                    </Link>
                  </div>
                ) : (
                  <>
                    {isAuthenticated() ? (
                      <div className="nav-item">
                        <Link to="/account" className="nav-link">
                          DASHBOARD
                        </Link>
                      </div>
                    ) : null}
                    <>
                      <div className="nav-item">
                        <Link
                          to="#logout"
                          className="nav-link"
                          onClick={(e) => {
                            logout();
                            e.preventDefault();
                          }}
                        >
                          LOGOUT
                        </Link>
                      </div>
                      <div className="nav-item">
                        <img
                          style={{ borderRadius: '7px', marginTop: '.2rem' }}
                          src={user.picture}
                          width="35"
                          height="35"
                          alt={`${user.name} Avatar`}
                        />
                      </div>
                    </>
                  </>
                )} */}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </header>
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
