import React from 'react';
import { css } from '@emotion/core';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Footer = () => (
  <footer
    css={css`
      margin-top: 17rem;
    `}
  >
    <div className="text-center py-4">
      © RSVision {'& '}
      <OutboundLink href="https://github.com/robSturcke">
        robSturcke
      </OutboundLink>{' '}
      {new Date().getFullYear()}
    </div>
  </footer>
);

export default Footer;
